var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.lessonInfo
    ? _c("div", { staticClass: "layout-container" }, [
        _c(
          "div",
          { staticClass: "layout-header" },
          [
            _c("CourseHeader", {
              attrs: {
                lessonNum: _vm.lessonInfo.lesson,
                pageNum: _vm.pageNum,
                pageNumTotal: _vm.lessonInfo.totalPage,
                authority: _vm.AUTH,
              },
              on: { jumpTo: _vm.changePage },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "layout-content" }, [
          _c("div", { staticClass: "layout-content-left" }, [
            _c("div", { staticClass: "layout-content-left-header" }, [
              _c(
                "div",
                { staticClass: "page-title-box" },
                [
                  _c("PageTitle", {
                    attrs: {
                      pageIndex: _vm.pageNum,
                      pageTitleList: _vm.lessonInfo.pageTitleArray,
                      authority: _vm.AUTH,
                    },
                    on: { displayWhichPage: _vm.showPage },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "control-box" }, [
                _vm.AUTH && !_vm.isShowTeacherMouse
                  ? _c(
                      "div",
                      { staticClass: "white-board-control" },
                      [
                        _c("WhiteBoardControl", {
                          attrs: { whiteBoradPage: _vm.controlWriteboard },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "page-turning" }, [
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.pageNum > 1,
                        expression: "pageNum > 1",
                      },
                    ],
                    staticClass: "previous",
                    on: { click: _vm.prevClick },
                  }),
                  _c("div", {
                    staticClass: "nextPrevious",
                    class: {
                      isInvisible: _vm.pageNum >= _vm.lessonInfo.totalPage,
                    },
                    on: { click: _vm.nextClick },
                  }),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                ref: "interactionCanvasBox",
                staticClass: "layout-content-left-display",
              },
              [
                !_vm.AUTH ? _c("div", { staticClass: "shade-box" }) : _vm._e(),
                _vm.isShowTeacherMouse
                  ? _c(
                      "div",
                      {
                        ref: "mouseMoveBox",
                        staticClass: "teacher-mouse-move-area",
                      },
                      [
                        _c("div", {
                          staticClass: "mouse-area",
                          style: {
                            left: _vm.mouseX + "px",
                            top: _vm.mouseY + "px",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _c("RouterView"),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "layout-content-right" },
            [_c("Interaction", { attrs: { authority: _vm.AUTH } })],
            1
          ),
        ]),
        _vm.isShowFinishedMask
          ? _c("div", { staticClass: "finshed-mask-area" }, [
              _c("div", { staticClass: "mask-content" }, [
                _vm._m(0),
                _vm._m(1),
                _c("div", { staticClass: "mask-tips" }, [
                  _c("p", [_vm._v("Your class is now finished!")]),
                  _c("p", [_vm._v("This window is about to close.")]),
                  _c("p", [
                    _vm._v(
                      " Don’t worry — you will be able to review this class in the Review section. "
                    ),
                  ]),
                  _c("div", { staticClass: "back-home" }, [
                    _c(
                      "div",
                      {
                        staticClass: "back-button",
                        on: { click: _vm.handleBackHome },
                      },
                      [_vm._v(" Return to Dashboard ")]
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mask-title font-hanzi-medium" }, [
      _c("span", [_vm._v("Class Finished")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mask-img" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/img/19-Dashboard/Home/class-finished.svg"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }