import * as recvSocketApi from "@/utils/socketGetMessage.js";

export function socketSwitch(data) {
  // console.log(data);
  switch (Number(data.clickType)) {
    case 10000:
      recvSocketApi.recvMsg_10000(data);
      break;
    case 10001:
      recvSocketApi.recvMsg_10001(data);
      break;
    case 10002:
      recvSocketApi.recvMsg_10002(data);
      break;
    case 10003:
      recvSocketApi.recvMsg_10003(data);
      break;
    case 1000401:
      recvSocketApi.recvMsg_1000401(data);
      break;
    case 1000402:
      recvSocketApi.recvMsg_1000402(data);
      break;
    case 1000403:
      recvSocketApi.recvMsg_1000403(data);
      break;
    case 1000404:
      recvSocketApi.recvMsg_1000404(data);
      break;
    case 1000405:
      recvSocketApi.recvMsg_1000405(data);
      break;
    case 1000406:
      recvSocketApi.recvMsg_1000406(data);
      break;
    case 1000407:
      recvSocketApi.recvMsg_1000407(data);
      break;
    case 1000408:
      recvSocketApi.recvMsg_1000408(data);
      break;
    case 1000409:
      recvSocketApi.recvMsg_1000409(data);
      break;

    /**
     * toolBar Socket 交互 开始
     */
    case 1000501:
      recvSocketApi.recvMsg_1000501(data);
      break;
    case 1000502:
      recvSocketApi.recvMsg_1000502(data);
      break;
    case 1000503:
      recvSocketApi.recvMsg_1000503(data);
      break;
    case 1000504:
      recvSocketApi.recvMsg_1000504(data);
      break;
    case 1000505:
      recvSocketApi.recvMsg_1000505(data);
      break;
    case 1000506:
      recvSocketApi.recvMsg_1000506(data);
      break;
    case 1000507:
      recvSocketApi.recvMsg_1000507(data);
      break;
    case 1000508:
      recvSocketApi.recvMsg_1000508(data);
      break;
    case 1000509:
      recvSocketApi.recvMsg_1000509(data);
      break;
    case 1000510:
      recvSocketApi.recvMsg_1000510(data);
      break;
    case 1000511:
      recvSocketApi.recvMsg_1000511(data);
      break;
    case 1000512:
      recvSocketApi.recvMsg_1000512(data);
      break;
    case 1000513:
      recvSocketApi.recvMsg_1000513(data);
      break;
    case 1000514:
      recvSocketApi.recvMsg_1000514(data);
      break;

    /**
     * toolBar Socket 交互 开始
     */
    case 10005:
      recvSocketApi.recvMsg_10005(data);
      break;
    case 10006:
      recvSocketApi.recvMsg_10006(data);
      break;
    case 11001:
      recvSocketApi.recvMsg_11001(data);
      break;

    case 20000:
      recvSocketApi.recvMsg_20000_StartStop(data);
      break;
    case 21000:
      recvSocketApi.recvMsg_21000_StartStop(data);
      break;
    case 20001:
      recvSocketApi.recvMsg_20001_StoryPage(data);
      break;
    case 2000101:
      recvSocketApi.recvMsg_2000101_StoryPage(data);
      break;
    case 20002:
      recvSocketApi.recvMsg_20002_RecognitionPage(data);
      break;
    case 21002:
      recvSocketApi.recvMsg_21002_RecognitionPage(data);
      break;
    case 2000201:
      recvSocketApi.recvMsg_2000201_RecognitionPage(data);
      break;
    case 2000301:
      recvSocketApi.recvMsg_2000301_WriteStrokePage(data);
      break;
    case 2000302:
      recvSocketApi.recvMsg_2000302_WriteStrokePage(data);
      break;
    case 2000303:
      recvSocketApi.recvMsg_2000303_WriteStrokePage(data);
      break;
    case 20004: // syllabus hover 效果 not need
      recvSocketApi.recvMsg_20004_SyllabusPage(data);
      break;
    // case 2000401: // syllabus hover 效果 not need
    //     recvSocketApi.recvMsg_2000401_SyllabusPageA(data);
    //     break;
    // case 2000402: // syllabus hover 效果
    //     recvSocketApi.recvMsg_2000402_SyllabusPageA(data);
    // break;
    case 2000501:
      recvSocketApi.recvMsg_2000501_PracticePage(data);
      break;
    case 2000502:
      recvSocketApi.recvMsg_2000502_PracticePage(data);
      break;
    case 2000601:
      recvSocketApi.recvMsg_2000601_WriteHanziPage(data);
      break;
    case 2000602:
      recvSocketApi.recvMsg_2000602_WriteHanziPage(data);
      break;
    case 2000603:
      recvSocketApi.recvMsg_2000603_WriteHanziPage(data);
      break;
    case 2000604:
      recvSocketApi.recvMsg_2000604_WriteHanziPage(data);
      break;
    case 20007:
      recvSocketApi.recvMsg_20007_WordCardsPage(data);
      break;
    case 2000701:
      recvSocketApi.recvMsg_2000701_WordCardsGroupPage(data);
      break;

    case 2000801:
      recvSocketApi.recvMsg_2000801_LearnReview(data);
      break;
    case 2000802:
      recvSocketApi.recvMsg_2000802_LearnReview(data);
      break;
    case 20009:
      recvSocketApi.recvMsg_20009_RocketGame(data);
      break;
    case 2001001:
      recvSocketApi.recvMsg_2001001_DragGame(data);
      break;
    case 2001002:
      recvSocketApi.recvMsg_2001002_DragGame(data);
      break;
    case 2001003:
      recvSocketApi.recvMsg_2001003_DragGame(data);
      break;
    case 20011:
      recvSocketApi.recvMsg_20011_MoveGame(data);
      break;
    case 20012:
      recvSocketApi.recvMsg_20012_FindStrokeGame(data);
      break;
    case 20013:
      recvSocketApi.recvMsg_20013_FindStrokeTrainGame(data);
      break;
    case 20014:
      recvSocketApi.recvMsg_20014_SpeechBubble(data);
      break;
    case 2001501:
      recvSocketApi.recvMsg_2001501_FindStrokePathGame(data);
      break;
    case 2001502:
      recvSocketApi.recvMsg_2001502_FindStrokePathGame(data);
      break;
    case 2001503:
      recvSocketApi.recvMsg_2001503_FindStrokePathGame(data);
      break;
    case 2001504:
      recvSocketApi.recvMsg_2001504_FindStrokePathGame(data);
      break;
    case 2001505:
      recvSocketApi.recvMsg_2001505_FindStrokePathGame(data);
      break;
    case 20016:
      recvSocketApi.recvMsg_20016_RankMapPage(data);
      break;
    case 20017:
      recvSocketApi.recvMsg_20017_SyllabusPage(data);
      break;
    case 20018:
      recvSocketApi.recvMsg_20018_NumberClickGame(data);
      break;
    case 20019:
      recvSocketApi.recvMsg_20019_RecognitionNumberGame(data);
      break;
    case 2002001:
      recvSocketApi.recvMsg_2002001_RecognitionNumberGame(data);
      break;
    case 2002002:
      recvSocketApi.recvMsg_2002002_RecognitionNumberGame(data);
      break;
    case 2002003:
      recvSocketApi.recvMsg_2002003_RecognitionNumberGame(data);
      break;
    case 20021:
      recvSocketApi.recvMsg_20021_RecognitionPinyinGame(data);
      break;
    case 2002101:
      recvSocketApi.recvMsg_2002101_RecognitionPinyinGame(data);
      break;
    case 20022:
      recvSocketApi.recvMsg_20022_RobotJumpGame(data);
      break;
    case 2002201:
      recvSocketApi.recvMsg_2002201_RobotJumpGame(data);
      break;
    case 2002301:
      recvSocketApi.recvMsg_2002301_PinyinSummary(data);
      break;
    case 2002302:
      recvSocketApi.recvMsg_2002302_PinyinSummary(data);
      break;
    case 2002401:
      recvSocketApi.recvMsg_2002401_WriteStrokeReview(data);
      break;
    case 2002402:
      recvSocketApi.recvMsg_2002402_WriteStrokeReview(data);
      break;
    case 2002501:
      recvSocketApi.recvMsg_2002501_FindNumberGame(data);
      break;
    case 2002502:
      recvSocketApi.recvMsg_2002502_FindNumberGame(data);
      break;
    case 2002601:
      recvSocketApi.recvMsg_2002601_WritePinyinPage(data);
      break;
    case 2002602:
      recvSocketApi.recvMsg_2002602_WritePinyinPage(data);
      break;
    case 2002603:
      recvSocketApi.recvMsg_2002603_WritePinyinPage(data);
      break;
    case 2002604:
      recvSocketApi.recvMsg_2002604_WritePinyinPage(data);
      break;
    case 20027:
      recvSocketApi.recvMsg_20027_FindElementByPicGame(data);
      break;
    case 2002701:
      recvSocketApi.recvMsg_2002701_FindElementByPicGame(data);
      break;
    case 2002801:
      recvSocketApi.recvMsg_2002801_WriteHanziReviewPage(data);
      break;
    case 2002802:
      recvSocketApi.recvMsg_2002802_WriteHanziReviewPage(data);
      break;
    case 2002803:
      recvSocketApi.recvMsg_2002803_WriteHanziReviewPage(data);
      break;
    case 2002804:
      recvSocketApi.recvMsg_2002804_WriteHanziReviewPage(data);
      break;
    case 2002805:
      recvSocketApi.recvMsg_2002805_WriteHanziReviewPage(data);
      break;
    case 2002806:
      recvSocketApi.recvMsg_2002806_WriteHanziReviewPage(data);
      break;
    case 20029:
      recvSocketApi.recvMsg_20029_AppleTreeGame(data);
      break;
    case 2003001:
      recvSocketApi.recvMsg_2003001_ListenThenChooseWordsGame(data);
      break;
    case 2003002:
      recvSocketApi.recvMsg_2003002_ListenThenChooseWordsGame(data);
      break;
    case 20031:
      recvSocketApi.recvMsg_20031_FrogJumpGame(data);
      break;
    case 20032:
      recvSocketApi.recvMsg_20032_RaceGame(data);
      break;
    case 2003003:
      recvSocketApi.recvMsg_2003003_ListenThenChooseWordsGame(data);
      break;
    case 2003004:
      recvSocketApi.recvMsg_2003004_ListenThenChooseWordsGame(data);
      break;
    case 2003301:
      recvSocketApi.recvMsg_2003301_speakingPage(data);
      break;
    case 2003302:
      recvSocketApi.recvMsg_2003302_speakingPage(data);
      break;
    case 2003303:
      recvSocketApi.recvMsg_2003303_speakingPage(data);
      break;
    case 2003304:
      recvSocketApi.recvMsg_2003304_speakingPage(data);
      break;
    case 2003305:
      recvSocketApi.recvMsg_2003305_speakingPage(data);
      break;
    case 20034:
      recvSocketApi.recvMsg_20034_FindStrokeReviewGame(data);
      break;
    case 20035:
      recvSocketApi.recvMsg_20035_BasketballPinyinGame(data);
      break;
    case 2003501:
      recvSocketApi.recvMsg_2003501_BasketballPinyinGame(data);
      break;
    case 2003601:
      recvSocketApi.recvMsg_2003601_puzzlePinyinGame(data);
      break;
    case 2003602:
      recvSocketApi.recvMsg_2003602_puzzlePinyinGame(data);
      break;
    case 2003603:
      recvSocketApi.recvMsg_2003603_puzzlePinyinGame(data);
      break;
    case 2003604:
      recvSocketApi.recvMsg_2003604_puzzlePinyinGame(data);
      break;
    case 2003701:
      recvSocketApi.recvMsg_2003701_DragCorrectPicGame(data);
      break;
    case 2003702:
      recvSocketApi.recvMsg_2003702_DragCorrectPicGame(data);
      break;
    case 20038:
      recvSocketApi.recvMsg_20038_RainDropGame(data);
      break;
    case 20039:
      recvSocketApi.recvMsg_20039_PinyinMoveGame(data);
      break;
    case 20040:
      recvSocketApi.recvMsg_20040_BingoGame(data);
      break;
    case 20041:
      recvSocketApi.recvMsg_20041_MatchTheCardsGame(data);
      break;
    case 2004101:
      recvSocketApi.recvMsg_2004101_MatchTheGroupCardsGame(data);
      break;
    case 2004102:
      recvSocketApi.recvMsg_2004102_MatchTheGroupCardsGame(data);
      break;
    case 20042:
      recvSocketApi.recvMsg_20042_SpeakingByTurntableGame(data);
      break;
    case 20043:
      recvSocketApi.recvMsg_20043_ChooseWordsByPicGame(data);
      break;
    case 2004301:
      recvSocketApi.recvMsg_2004301_ChooseWordsByPicGame(data);
      break;
    case 20044:
      recvSocketApi.recvMsg_20044_ClickRevealGame(data);
      break;
    case 2004501:
      recvSocketApi.recvMsg_2004501_DragStrokeToImgGame(data);
      break;
    case 2004502:
      recvSocketApi.recvMsg_2004502_DragStrokeToImgGame(data);
      break;
    case 2004503:
      recvSocketApi.recvMsg_2004503_DragoverStrokeToImgGame(data);
      break;
    case 2004601:
      recvSocketApi.recvMsg_2004601_SpeakingSentence(data);
      break;
    case 2004602:
      recvSocketApi.recvMsg_2004602_SpeakingSentence(data);
      break;
    case 20047:
      recvSocketApi.recvMsg_20047_OverturnPuzzleGame(data);
      break;
    case 2004801:
      recvSocketApi.recvMsg_2004801_FamilyTreeGame(data);
      break;
    case 2004802:
      recvSocketApi.recvMsg_2004802_FamilyTreeGame(data);
      break;
    case 20049:
      recvSocketApi.recvMsg_20049_ChoosePicBySentenceGame(data);
      break;
    case 20050:
      recvSocketApi.recvMsg_20050_SeesawGame(data);
      break;
    case 2005001:
      recvSocketApi.recvMsg_2005001_SeesawGame(data);
      break;
    case 2005101:
      recvSocketApi.recvMsg_2005101_TwitterGame(data);
      break;
    case 2005102:
      recvSocketApi.recvMsg_2005102_TwitterGame(data);
      break;
    case 2005201:
      recvSocketApi.recvMsg_2005201_ScratchPicGame(data);
      break;
    case 2005202:
      recvSocketApi.recvMsg_2005202_ScratchPicGame(data);
      break;
    case 2005301:
      recvSocketApi.recvMsg_2005301_SpeakingBubbleGame(data);
      break;
    case 2005302:
      recvSocketApi.recvMsg_2005302_SpeakingBubbleGame(data);
      break;
    case 20054:
      recvSocketApi.recvMsg_20054_MagnifierGame(data);
      break;
    case 20055:
      recvSocketApi.recvMsg_20055_TigerMachineGame(data);
      break;
    case 20056:
      recvSocketApi.recvMsg_20056_GuideGame(data);
      break;
    case 2005701:
      recvSocketApi.recvMsg_2005701_DragToGressGame(data);
      break;
    case 2005702:
      recvSocketApi.recvMsg_2005702_DragToGressGame(data);
      break;
    case 2005704:
      recvSocketApi.recvMsg_2005704_DragToGressGame(data);
      break;
    case 2005703:
      recvSocketApi.recvMsg_2005703_DragToGroupCakeGame(data);
      break;
    case 20058:
      recvSocketApi.recvMsg_20058_MirrorGame(data);
      break;
    case 20059:
      recvSocketApi.recvMsg_20059_WordImgCardsPage(data);
      break;

    case 2006001:
      recvSocketApi.recvMsg_2006001_ClickImgToShowSentenceGame(data);
      break;
    case 2006002:
      recvSocketApi.recvMsg_2006002_ClickImgToShowSentenceGame(data);
      break;
    case 20061:
      recvSocketApi.recvMsg_20061_OpenBoxToFindGame(data);
      break;
    case 2006201:
      recvSocketApi.recvMsg_2006201_DragFruitsGanme(data);
      break;
    case 2006202:
      recvSocketApi.recvMsg_2006202_DragFruitsGanme(data);
      break;
    case 2006203:
      recvSocketApi.recvMsg_2006203_DragFruitsGanme(data);
      break;
    case 20063:
      recvSocketApi.recvMsg_20063_DrawingPage(data);
      break;
    case 2006301:
      recvSocketApi.recvMsg_2006301_DrawingGroupPage(data);
      break;
    case 20064:
      recvSocketApi.recvMsg_20064_FindElementGame(data);
      break;
    case 2006401:
      recvSocketApi.recvMsg_2006401_FindElementGroupGame(data);
      break;
    case 20065:
      recvSocketApi.recvMsg_20065_EatingFoodGame(data);
      break;

    case 30001:
      recvSocketApi.recvMsg_30001(data);
      break;
    case 3000101:
      recvSocketApi.recvMsg_3000101(data);
      break;
    case 30002:
      recvSocketApi.recvMsg_30002(data);
      break;
    case 3000401:
      recvSocketApi.recvMsg_3000401(data);
      break;
    case 3000402:
      recvSocketApi.recvMsg_3000402(data);
      break;
    case 30005:
      recvSocketApi.recvMsg_30005(data);
      break;
    case 3000501:
      recvSocketApi.recvMsg_3000501(data);
      break;
    case 30006:
      recvSocketApi.recvMsg_30006(data);
      break;
    case 3000601:
      recvSocketApi.recvMsg_3000601(data);
      break;
    case 3000701:
      recvSocketApi.recvMsg_3000701(data);
      break;
    case 3000702:
      recvSocketApi.recvMsg_3000702(data);
      break;
    case 3000703:
      recvSocketApi.recvMsg_3000703(data);
      break;
    case 3000704:
      recvSocketApi.recvMsg_3000704(data);
      break;
    case 30013:
      recvSocketApi.recvMsg_30013(data);
      break;
    case 3001301:
      recvSocketApi.recvMsg_3001301(data);
      break;
    case 30016:
      recvSocketApi.recvMsg_30016(data);
      break;
  }
}
