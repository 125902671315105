<template>
  <div class="course-container">
    <Layout />

  </div>
</template>

<script>
import Layout from "@/components/Course/Layout";
export default {
  name: "courses",
  components: {
    Layout,
  },
};
</script>
<style lang="scss" scoped>
.course-container {
  width: 100%;
  height: 100%;
}
</style>
