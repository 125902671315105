var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "whiteBoard-container" }, [
    _c("div", { staticClass: "control-box" }, [
      _c("div", { staticClass: "control-box-content" }, [
        _c(
          "div",
          {
            staticClass: "control-box-area",
            class: {
              noRadius: _vm.isBrushOpen || _vm.isInsertText || _vm.isShapeUse,
            },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isBrushOpen,
                    expression: "isBrushOpen",
                  },
                ],
                staticClass: "select-area-item brush-area",
                class: { bgcActive: _vm.isBrushOpen },
              },
              [
                _c(
                  "div",
                  { staticClass: "line-width-content" },
                  _vm._l(_vm.lineWidthArr, function (item, index) {
                    return _c("div", {
                      key: index,
                      staticClass: "line-width-item",
                      class: {
                        "line-width-active": index === (_vm.lineWidth - 1) / 2,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.chooseLineWidth(item)
                        },
                      },
                    })
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "color" },
                  [
                    _c("div", { staticClass: "color-border" }, [
                      _c("div", {
                        staticClass: "color-area",
                        style: { background: _vm.drawColor },
                        on: { click: _vm.handleOpenChooseColor },
                      }),
                    ]),
                    _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
                      _vm.isColorChooseUse
                        ? _c(
                            "div",
                            { staticClass: "color-box" },
                            _vm._l(_vm.predefineColors, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "color-border-box",
                                  class: {
                                    "active-color":
                                      _vm.currentColorNum === index,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.chooseColor(item)
                                    },
                                    mouseenter: function ($event) {
                                      _vm.currentColorNum = index
                                    },
                                  },
                                },
                                [
                                  _c("div", {
                                    staticClass: "color-item",
                                    style: {
                                      background: item,
                                    },
                                  }),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "dis-line" }, [_vm._v("|")]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isInsertText,
                    expression: "isInsertText",
                  },
                ],
                staticClass: "select-area-item text-area",
                class: { bgcActive: _vm.isInsertText },
              },
              [
                _c("div", { staticClass: "text-content" }, [
                  _c(
                    "div",
                    { staticClass: "select-box" },
                    [
                      _c("div", { staticClass: "size" }, [
                        _vm._v(_vm._s(_vm.textFontSize)),
                      ]),
                      _c("div", { staticClass: "down-image" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/02-Toolbar/down-blue.svg"),
                            alt: "",
                          },
                          on: { click: _vm.openFontSelect },
                        }),
                      ]),
                      _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
                        _vm.isShowSelectBox
                          ? _c(
                              "div",
                              { staticClass: "option-area" },
                              _vm._l(50, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "option-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.chooseTextFontSize(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item) + " ")]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "color" },
                  [
                    _c("div", {
                      staticClass: "color-area",
                      style: { background: _vm.drawColor },
                      on: { click: _vm.handleOpenChooseColor },
                    }),
                    _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
                      _vm.isColorChooseUse
                        ? _c(
                            "div",
                            { staticClass: "color-box" },
                            _vm._l(_vm.predefineColors, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "color-border-box",
                                  class: {
                                    "active-color":
                                      _vm.currentColorNum === index,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.chooseColor(item)
                                    },
                                    mouseenter: function ($event) {
                                      _vm.currentColorNum = index
                                    },
                                  },
                                },
                                [
                                  _c("div", {
                                    staticClass: "color-item",
                                    style: {
                                      background: item,
                                    },
                                  }),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "dis-line" }, [_vm._v("|")]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShapeUse,
                    expression: "isShapeUse",
                  },
                ],
                staticClass: "select-area-item shape-area",
                class: { bgcActive: _vm.isShapeUse },
              },
              [
                _c(
                  "div",
                  { staticClass: "shape-content" },
                  _vm._l(_vm.shapeTypeList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "square",
                        on: {
                          click: function ($event) {
                            return _vm.chooseShape(index)
                          },
                        },
                      },
                      [
                        _vm.shapeType === index
                          ? _c("img", {
                              attrs: {
                                src: require(`@/assets/img/02-Toolbar/${item.name}-active.svg`),
                                title: item.chinese,
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src: require(`@/assets/img/02-Toolbar/${item.name}.svg`),
                                title: item.chinese,
                              },
                            }),
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "color" },
                  [
                    _c("div", {
                      staticClass: "color-area",
                      style: { background: _vm.drawColor },
                      on: { click: _vm.handleOpenChooseColor },
                    }),
                    _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
                      _vm.isColorChooseUse
                        ? _c(
                            "div",
                            { staticClass: "color-box" },
                            _vm._l(_vm.predefineColors, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "color-border-box",
                                  class: {
                                    "active-color":
                                      _vm.currentColorNum === index,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.chooseColor(item)
                                    },
                                    mouseenter: function ($event) {
                                      _vm.currentColorNum = index
                                    },
                                  },
                                },
                                [
                                  _c("div", {
                                    staticClass: "color-item",
                                    style: {
                                      background: item,
                                    },
                                  }),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "dis-line" }, [_vm._v("|")]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "mouse item",
                on: { click: _vm.handleClickMouse },
              },
              [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isMouseClick,
                      expression: "isMouseClick",
                    },
                  ],
                  attrs: {
                    src: require("@/assets/img/02-Toolbar/tool-cursor-active.svg"),
                    title: "鼠标",
                  },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isMouseClick,
                      expression: "!isMouseClick",
                    },
                  ],
                  attrs: {
                    src: require("@/assets/img/02-Toolbar/tool-cursor.svg"),
                    title: "鼠标",
                  },
                }),
              ]
            ),
            _c(
              "div",
              { staticClass: "brush item", on: { click: _vm.handleDrawLine } },
              [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isBrushOpen,
                      expression: "!isBrushOpen",
                    },
                  ],
                  attrs: {
                    src: require("@/assets/img/02-Toolbar/tool-brush.svg"),
                    title: "笔刷",
                  },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isBrushOpen,
                      expression: "isBrushOpen",
                    },
                  ],
                  attrs: {
                    src: require("@/assets/img/02-Toolbar/tool-brush-active.svg"),
                    title: "笔刷",
                  },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "text item",
                on: { click: _vm.handleInsertWords },
              },
              [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isInsertText,
                      expression: "isInsertText",
                    },
                  ],
                  attrs: {
                    src: require("@/assets/img/02-Toolbar/tool-text-active.svg"),
                    title: "文字",
                  },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isInsertText,
                      expression: "!isInsertText",
                    },
                  ],
                  attrs: {
                    src: require("@/assets/img/02-Toolbar/tool-text.svg"),
                    title: "文字",
                  },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "shape item",
                on: { click: _vm.handleChooseShape },
              },
              [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShapeUse,
                      expression: "isShapeUse",
                    },
                  ],
                  attrs: {
                    src: require("@/assets/img/02-Toolbar/tool-shapes-active.svg"),
                    title: "形状",
                  },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isShapeUse,
                      expression: "!isShapeUse",
                    },
                  ],
                  attrs: {
                    src: require("@/assets/img/02-Toolbar/tool-shapes.svg"),
                    title: "形状",
                  },
                }),
              ]
            ),
            _c(
              "div",
              { staticClass: "eraser item", on: { click: _vm.handleEraser } },
              [
                !_vm.isEraserUse
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/02-Toolbar/tool-eraser.svg"),
                        title: "橡皮擦",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/assets/img/02-Toolbar/tool-eraser-active.svg"),
                        title: "橡皮擦",
                      },
                    }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "prev item",
                on: {
                  click: function ($event) {
                    return _vm.handleControl("prev")
                  },
                },
              },
              [
                !_vm.isPrev
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/02-Toolbar/tool-undo.svg"),
                        title: "上一步",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/assets/img/02-Toolbar/tool-undo-active.svg"),
                        title: "上一步",
                      },
                    }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "next item",
                on: {
                  click: function ($event) {
                    return _vm.handleControl("next")
                  },
                },
              },
              [
                !_vm.isNext
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/02-Toolbar/tool-redo.svg"),
                        title: "下一步",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/assets/img/02-Toolbar/tool-redo-active.svg"),
                        title: "下一步",
                      },
                    }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "clear item",
                on: {
                  click: function ($event) {
                    return _vm.handleControl("clear")
                  },
                },
              },
              [
                !_vm.isClear
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/02-Toolbar/tool-delete.svg"),
                        title: "清除",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/assets/img/02-Toolbar/tool-delete-active.svg"),
                        title: "清除",
                      },
                    }),
              ]
            ),
            false
              ? _c(
                  "div",
                  {
                    staticClass: "clear item",
                    on: {
                      click: function ($event) {
                        return _vm.handleControl("authority")
                      },
                    },
                  },
                  [
                    true
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/img/02-Toolbar/icon-control-off.svg"),
                            title: "权限",
                          },
                        })
                      : _c("img", {
                          attrs: {
                            src: require("@/assets/img/02-Toolbar/icon-control-on.svg"),
                            title: "权限",
                          },
                        }),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }