var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "interaction-container" }, [
    _c("audio", { ref: "bonusStarSound", attrs: { src: _vm.songUrl } }),
    _c(
      "div",
      { staticClass: "video-content" },
      [
        _vm._l(_vm.remoteStreamList, function (x, index) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false",
                },
              ],
              key: index,
              staticClass: "content remote-video",
            },
            [
              _c("p", { staticClass: "nickname" }, [
                _vm._v(_vm._s(x.nickname)),
              ]),
              _c(
                "div",
                {
                  staticClass: "icon_camera",
                  on: { click: _vm.handleRemoteCamera },
                },
                [
                  _vm.isRemoteVideoOpen
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/img/19-Dashboard/icon-camera-blue.svg"),
                          alt: "",
                        },
                      })
                    : _c("img", {
                        attrs: {
                          src: require("@/assets/img/19-Dashboard/icon-camera-blue-off.svg"),
                          alt: "",
                        },
                      }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "icon_micro",
                  on: { click: _vm.handleRemoteMicro },
                },
                [
                  _vm.isRemoteMicroOpen
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/img/19-Dashboard/icon-micro-on.svg"),
                        },
                      })
                    : _c("img", {
                        attrs: {
                          src: require("@/assets/img/19-Dashboard/icon-micro-off.svg"),
                        },
                      }),
                ]
              ),
              _c("div", { staticClass: "videoCont", attrs: { id: "remote" } }),
            ]
          )
        }),
        _c("div", { staticClass: "content remote-video" }, [
          _c("div", { staticClass: "local-video-content" }, [
            _c("p", { staticClass: "nickname" }, [_vm._v("测试")]),
            _c(
              "div",
              {
                staticClass: "icon_camera",
                on: { click: _vm.handleRemoteCamera },
              },
              [
                _vm.isRemoteVideoOpen
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/19-Dashboard/icon-camera-blue.svg"),
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/assets/img/19-Dashboard/icon-camera-blue-off.svg"),
                        alt: "",
                      },
                    }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "icon_micro",
                on: { click: _vm.handleRemoteMicro },
              },
              [
                _vm.isRemoteMicroOpen
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/19-Dashboard/icon-micro-on.svg"),
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/assets/img/19-Dashboard/icon-micro-off.svg"),
                      },
                    }),
              ]
            ),
            _c("div", { staticClass: "videoCont", attrs: { id: "remote" } }),
          ]),
        ]),
        _c("div", { staticClass: "content local-video" }, [
          _c("div", { staticClass: "student-star" }, [
            _c("span", [
              _c("img", {
                class: { animatedstar: _vm.clickedOnStar },
                attrs: {
                  src: require("@/assets/img/07-Stars/star-big.svg"),
                  alt: "",
                },
              }),
              _vm.clickedOnStar === 1
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/img/07-Stars/star-big.svg"),
                      alt: "",
                    },
                  })
                : _vm._e(),
            ]),
            _c("span", [_vm._v(_vm._s(_vm.starNum))]),
          ]),
          _c("p", { staticClass: "nickname" }, [_vm._v("You")]),
          _c("div", { staticClass: "local-video-content" }, [
            _c(
              "div",
              {
                staticClass: "icon_camera",
                on: { click: _vm.handleLocalCamera },
              },
              [
                _vm.isVideoOpen
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/19-Dashboard/icon-camera-blue.svg"),
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/assets/img/19-Dashboard/icon-camera-blue-off.svg"),
                        alt: "",
                      },
                    }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "icon_micro",
                on: { click: _vm.handleLocalMicro },
              },
              [
                _vm.isMicroOpen
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/19-Dashboard/icon-micro-on.svg"),
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/assets/img/19-Dashboard/icon-micro-off.svg"),
                      },
                    }),
              ]
            ),
            _c("div", { staticClass: "videoCont", attrs: { id: "local" } }),
          ]),
        ]),
      ],
      2
    ),
    _vm.isShowNav
      ? _c("div", { staticClass: "student_nav" }, [
          _c("div", { staticClass: "nav_header" }, [
            _c("p", { on: { click: _vm.closeNav } }, [_vm._v("X")]),
            _c("p", [_vm._v("Content")]),
          ]),
          _c("div", { staticClass: "nav_container" }, [
            _c(
              "div",
              { staticClass: "nav_course_one" },
              [
                _vm._m(0),
                _vm._l(_vm.lessonList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "nav_lesson",
                      class: {
                        active: _vm.currentLessonIndex === item.lessonId,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.jumpLesson(item.lessonId)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "nav_lesson_title" }, [
                        _vm._v(" " + _vm._s(item.lessonName) + " "),
                      ]),
                      _vm.currentLessonIndex === item.lessonId
                        ? _c(
                            "p",
                            { staticClass: "nav_lesson_status red_color" },
                            [_vm._v(" CURRENT ")]
                          )
                        : _vm._e(),
                    ]
                  )
                }),
              ],
              2
            ),
            _c(
              "div",
              {
                staticClass: "add_new_nav",
                on: {
                  click: function ($event) {
                    return _vm.typeAdd_click()
                  },
                },
              },
              [_c("p", [_vm._v("Add new")]), _c("p", [_vm._v("+")])]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.isShowChat
      ? _c("div", { staticClass: "chat_nav" }, [
          _c("div", { staticClass: "nav_header" }, [
            _c("p", { on: { click: _vm.closeChat } }, [_vm._v("X")]),
            _c("p", [_vm._v("Chat")]),
          ]),
          _c("div", { staticClass: "chat_container" }, [
            _vm.classType == 1
              ? _c("div", { staticClass: "interactContent" }, [
                  _c(
                    "div",
                    {
                      staticClass: "interactContent_main",
                      style: { width: "100%", height: "100%" },
                    },
                    [
                      _c("Chat", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.interactType == 1,
                            expression: "interactType == 1",
                          },
                        ],
                        ref: "chatkids",
                        staticClass: "chat",
                      }),
                      _c("Particopants", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.interactType == 2,
                            expression: "interactType == 2",
                          },
                        ],
                        staticClass: "particopants",
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nav_course" }, [
      _c("span", [_vm._v("LEVEL 1")]),
      _c("span", [_vm._v(" | ")]),
      _c("span", [_vm._v(" 我的学校")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }