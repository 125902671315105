<template>
  <div class="whiteBoard-container">
    <div class="control-box">
      <div class="control-box-content">
        <div
          class="control-box-area"
          :class="{ noRadius: isBrushOpen || isInsertText || isShapeUse }"
        >
          <!-- lineWidth -->
          <div
            class="select-area-item brush-area"
            v-show="isBrushOpen"
            :class="{ bgcActive: isBrushOpen }"
          >
            <div class="line-width-content">
              <div
                class="line-width-item"
                v-for="(item, index) in lineWidthArr"
                :key="index"
                @click="chooseLineWidth(item)"
                :class="{ 'line-width-active': index === (lineWidth - 1) / 2 }"
              ></div>
            </div>
            <div class="color">
              <div class="color-border">
                <div
                  class="color-area"
                  @click="handleOpenChooseColor"
                  :style="{ background: drawColor }"
                />
              </div>
              <transition name="el-zoom-in-top">
                <div class="color-box" v-if="isColorChooseUse">
                  <div
                    class="color-border-box"
                    v-for="(item, index) in predefineColors"
                    :key="index"
                    @click="chooseColor(item)"
                    :class="{ 'active-color': currentColorNum === index }"
                    @mouseenter="currentColorNum = index"
                  >
                    <div
                      class="color-item"
                      :style="{
                        background: item,
                      }"
                    ></div>
                  </div>
                </div>
              </transition>
            </div>
            <div class="dis-line">|</div>
          </div>

          <!-- text -->
          <div
            class="select-area-item text-area"
            v-show="isInsertText"
            :class="{ bgcActive: isInsertText }"
          >
            <div class="text-content">
              <div class="select-box">
                <div class="size">{{ textFontSize }}</div>
                <div class="down-image">
                  <img
                    src="@/assets/img/02-Toolbar/down-blue.svg"
                    alt=""
                    @click="openFontSelect"
                  />
                </div>
                <transition name="el-zoom-in-top">
                  <div class="option-area" v-if="isShowSelectBox">
                    <div
                      class="option-item"
                      v-for="(item, index) in 50"
                      :key="index"
                      @click="chooseTextFontSize(item)"
                    >
                      {{ item }}
                    </div>
                  </div>
                </transition>
              </div>
            </div>
            <div class="color">
              <div
                class="color-area"
                @click="handleOpenChooseColor"
                :style="{ background: drawColor }"
              />
              <transition name="el-zoom-in-top">
                <div class="color-box" v-if="isColorChooseUse">
                  <div
                    class="color-border-box"
                    v-for="(item, index) in predefineColors"
                    :key="index"
                    @click="chooseColor(item)"
                    :class="{ 'active-color': currentColorNum === index }"
                    @mouseenter="currentColorNum = index"
                  >
                    <div
                      class="color-item"
                      :style="{
                        background: item,
                      }"
                    ></div>
                  </div>
                </div>
              </transition>
            </div>
            <div class="dis-line">|</div>
          </div>

          <!-- shape -->
          <div
            class="select-area-item shape-area"
            v-show="isShapeUse"
            :class="{ bgcActive: isShapeUse }"
          >
            <div class="shape-content">
              <div
                class="square"
                v-for="(item, index) in shapeTypeList"
                :key="index"
                @click="chooseShape(index)"
              >
                <img
                  :src="
                    require(`@/assets/img/02-Toolbar/${item.name}-active.svg`)
                  "
                  :title="item.chinese"
                  v-if="shapeType === index"
                />
                <img
                  :src="require(`@/assets/img/02-Toolbar/${item.name}.svg`)"
                  :title="item.chinese"
                  v-else
                />
              </div>
            </div>
            <div class="color">
              <div
                class="color-area"
                @click="handleOpenChooseColor"
                :style="{ background: drawColor }"
              />
              <transition name="el-zoom-in-top">
                <div class="color-box" v-if="isColorChooseUse">
                  <div
                    class="color-border-box"
                    v-for="(item, index) in predefineColors"
                    :key="index"
                    @click="chooseColor(item)"
                    :class="{ 'active-color': currentColorNum === index }"
                    @mouseenter="currentColorNum = index"
                  >
                    <div
                      class="color-item"
                      :style="{
                        background: item,
                      }"
                    ></div>
                  </div>
                </div>
              </transition>
            </div>
            <div class="dis-line">|</div>
          </div>

          <div class="mouse item" @click="handleClickMouse">
            <img
              src="@/assets/img/02-Toolbar/tool-cursor-active.svg"
              title="鼠标"
              v-show="isMouseClick"
            />
            <img
              src="@/assets/img/02-Toolbar/tool-cursor.svg"
              title="鼠标"
              v-show="!isMouseClick"
            />
          </div>
          <div class="brush item" @click="handleDrawLine">
            <!-- <img src="@/assets/img/kidsClient/whiteBoard/hand-inactive.svg" title="上一步" /> -->
            <img
              src="@/assets/img/02-Toolbar/tool-brush.svg"
              title="笔刷"
              v-show="!isBrushOpen"
            />
            <img
              src="@/assets/img/02-Toolbar/tool-brush-active.svg"
              title="笔刷"
              v-show="isBrushOpen"
            />
          </div>
          <div class="text item" @click="handleInsertWords">
            <img
              src="@/assets/img/02-Toolbar/tool-text-active.svg"
              title="文字"
              v-show="isInsertText"
            />
            <img
              src="@/assets/img/02-Toolbar/tool-text.svg"
              title="文字"
              v-show="!isInsertText"
            />
          </div>
          <div class="shape item" @click="handleChooseShape">
            <img
              src="@/assets/img/02-Toolbar/tool-shapes-active.svg"
              title="形状"
              v-show="isShapeUse"
            />
            <img
              src="@/assets/img/02-Toolbar/tool-shapes.svg"
              title="形状"
              v-show="!isShapeUse"
            />
          </div>
          <div class="eraser item" @click="handleEraser">
            <img
              src="@/assets/img/02-Toolbar/tool-eraser.svg"
              title="橡皮擦"
              v-if="!isEraserUse"
            />
            <img
              src="@/assets/img/02-Toolbar/tool-eraser-active.svg"
              title="橡皮擦"
              v-else
            />
          </div>
          <div class="prev item" @click="handleControl('prev')">
            <img
              src="@/assets/img/02-Toolbar/tool-undo.svg"
              title="上一步"
              v-if="!isPrev"
            />
            <img
              src="@/assets/img/02-Toolbar/tool-undo-active.svg"
              title="上一步"
              v-else
            />
          </div>
          <div class="next item" @click="handleControl('next')">
            <img
              src="@/assets/img/02-Toolbar/tool-redo.svg"
              title="下一步"
              v-if="!isNext"
            />
            <img
              src="@/assets/img/02-Toolbar/tool-redo-active.svg"
              title="下一步"
              v-else
            />
          </div>
          <div class="clear item" @click="handleControl('clear')">
            <img
              src="@/assets/img/02-Toolbar/tool-delete.svg"
              title="清除"
              v-if="!isClear"
            />
            <img
              src="@/assets/img/02-Toolbar/tool-delete-active.svg"
              title="清除"
              v-else
            />
          </div>
          <!-- <div class="dis-line">|</div> -->
          <!--  全局权限开关 学生端没有-->
          <div
            class="clear item"
            @click="handleControl('authority')"
            v-if="false"
          >
            <img
              src="@/assets/img/02-Toolbar/icon-control-off.svg"
              title="权限"
              v-if="true"
            />
            <img
              src="@/assets/img/02-Toolbar/icon-control-on.svg"
              title="权限"
              v-else
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageType: 1, // 0 画板  1全局
      drawColor: "#CD4C3F", //画笔颜色
      lineWidth: 3, // 画笔粗细
      textFontSize: 25, // 默认字体大小 16px
      isClear: false,
      isEraserUse: false,
      isPrev: false,
      isNext: false,
      isColorChooseUse: false,
      isBrushOpen: false, // 笔刷选择器,控制canvas打开或者关闭
      isShapeUse: false,
      isInsertText: false,
      isMouseClick: true,
      shapeType: 0, // 图形种类, 0 方形 1  圆形 2 三角形

      isShowSelectBox: true,
      currentColorNum: 2,
      predefineColors: [
        // 预设颜色
        "#F4D737",
        "#224E96",
        "#CD4C3F",
        "#F7F4E0",
        "#ffffff",
        "#F5F5F5",
        "#CFCECB",
        "#000000",
      ],
      lineWidthArr: [1, 3, 5],
      isShowTips: false,
      shapeTypeList: [
        {
          name: "square",
          type: 0,
          chinese: "方形",
        },
        {
          name: "circle",
          type: 1,
          chinese: "圆形",
        },
        {
          name: "triangle",
          type: 2,
          chinese: "三角形",
        },
      ],

      clickMouseSocketInfo: {},
      clickDrawLineSocketInfo: {},
      clickInsertWordsSocketInfo: {},
      clickChooseShapeSocketInfo: {},
      clickUseEraserSocketInfo: {},
      clickControlActionSocketInfo: {},
      openDrawColorSocketInfo: {},
      chooseDrawColorSocketInfo: {},
      chooseLineWidthSocketInfo: {},
      openFontSizeSocketInfo: {},
      chooseFontSizeSocketInfo: {},
      chooseShapeTypeSocketInfo: {},
    };
  },

  mounted() {
    this.$bus.$on("startStrokePage", (val) => {
      console.log(val, "画笔");
      this.isBrushOpen = val;
      this.isMouseClick = !val;
      if (val) {
        setTimeout(() => {
          this.$bus.$emit("chooseLine", true);
          this.$bus.$emit("chooseDrawColor", "#000");
          this.drawColor = "#000";
        }, 300);
      }
    });
    this.$bus.$on("changeWhiteBoardPage", (val) => {
      console.log("页面切换");
      this.pageType = val;
      this.initBoard();
      this.initToolBarButton();
      this.isMouseClick = true;
      console.log("切换页面++++++++++++++++++++");
    });
    // 打开全局
    this.$bus.$on("showGlobalCanvas", (index) => {
      this.pageType = 1;
      this.handleGlobalBoard(true, index);
    });

    // scratchBg
    this.$bus.$on("startScratchPage", (value) => {
      if (value) {
        this.handleEraser();
      } else {
        this.handleClickMouse();
      }
    });

    /**
     * toolBar按钮的socket交互 开始
     */

    // 鼠标点击交互 1000501
    this.$bus.$on("toolBarMouseClick", () => {
      this.handleClickMouse("socket", true);
    });

    // 画线条点击交互 1000502
    this.$bus.$on("toolBarDrawLine", () => {
      this.handleDrawLine("socket", true);
    });

    // 文字插入交互 1000503
    this.$bus.$on("toolBarInsertWords", () => {
      this.handleInsertWords("socket", true);
    });

    // 形状选择交互 1000504
    this.$bus.$on("toolBarChooseShape", (type) => {
      this.handleChooseShape(type, true);
    });

    // 橡皮擦交互 1000505
    this.$bus.$on("toolBarUseEraser", () => {
      this.handleEraser("socket", true);
    });

    // 前 后 清空 交互 1000506
    this.$bus.$on("toolBarControlAction", ({ action }) => {
      console.log("清理action", action);
      this.handleControl(action, true);
    });

    // 打开关闭选择颜色交互 1000507
    this.$bus.$on("toolBarOpenChooseDrawColor", () => {
      this.handleOpenChooseColor("socket", true);
    });

    // 选择画笔颜色交互 1000508
    this.$bus.$on("toolBarChooseDrawColor", ({ color }) => {
      this.chooseColor(color, true);
    });

    // 切换线条宽度交互 1000509
    this.$bus.$on("toolBarChooseLineWidth", ({ lineWidth }) => {
      this.chooseLineWidth(lineWidth, true);
    });

    // 打开切换文字大小框交互 1000510
    this.$bus.$on("toolBarOpenFontSize", () => {
      this.openFontSelect("socket", true);
    });

    // 切换文字大小交互 10005011
    this.$bus.$on("toolBarChooseFontSize", ({ fontSize }) => {
      this.chooseTextFontSize(fontSize, true);
    });

    // 切换形状交互 10005012
    this.$bus.$on("toolBarChooseShapeType", ({ shapeType }) => {
      this.chooseShape(shapeType, true);
    });

    /**
     * toolBar按钮的socket交互  结束
     */
  },
  watch: {
    clickMouseSocketInfo: {
      handler(value) {
        const data = {
          clickType: 1000501,
          data: { value },
          text: "toolBar 鼠标点击",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickDrawLineSocketInfo: {
      handler(value) {
        const data = {
          clickType: 1000502,
          data: { value },
          text: "toolBar 画线点击",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickInsertWordsSocketInfo: {
      handler(value) {
        const data = {
          clickType: 1000503,
          data: { value },
          text: "toolBar 文字插入点击",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickChooseShapeSocketInfo: {
      handler(value) {
        const data = {
          clickType: 1000504,
          data: { value },
          text: "toolBar 形状点击",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickUseEraserSocketInfo: {
      handler(value) {
        const data = {
          clickType: 1000505,
          data: { value },
          text: "toolBar 橡皮擦点击",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickControlActionSocketInfo: {
      handler(value) {
        const data = {
          clickType: 1000506,
          data: { value },
          text: "toolBar 前景后退清空点击",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    openDrawColorSocketInfo: {
      handler(value) {
        const data = {
          clickType: 1000507,
          data: { value },
          text: "toolBar 打开选择颜色",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    chooseDrawColorSocketInfo: {
      handler(value) {
        const data = {
          clickType: 1000508,
          data: { value },
          text: "toolBar 选择颜色点击",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    chooseLineWidthSocketInfo: {
      handler(value) {
        const data = {
          clickType: 1000509,
          data: { value },
          text: "toolBar 选择线条宽度",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    openFontSizeSocketInfo: {
      handler(value) {
        const data = {
          clickType: 1000510,
          data: { value },
          text: "toolBar 打开选择字体框",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    chooseFontSizeSocketInfo: {
      handler(value) {
        const data = {
          clickType: 1000511,
          data: { value },
          text: "toolBar 字体大小点击",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    chooseShapeTypeSocketInfo: {
      handler(value) {
        const data = {
          clickType: 1000512,
          data: { value },
          text: "toolBar 鼠标点击",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  destroyed() {
    this.$bus.$off("changeWhiteBoardPage");
    this.$bus.$off("toolBarInteraction");
    this.$bus.$off("startScratchPage");
    // this.isBrushOpen = false;

    this.$bus.$off("toolBarMouseClick");
    this.$bus.$off("toolBarDrawLine");
    this.$bus.$off("toolBarInsertWords");
    this.$bus.$off("toolBarChooseShape");
    this.$bus.$off("toolBarUseEraser");
    this.$bus.$off("toolBarControlAction");
    this.$bus.$off("toolBarChooseDrawColor");
    this.$bus.$off("toolBarChooseLineWidth");
    this.$bus.$off("toolBarOpenFontSize");
    this.$bus.$off("toolBarChooseFontSize");
  },
  methods: {
    initBoard() {
      this.drawColor = "#cd4c3f"; //画笔颜色
      this.lineWidth = 3; // 画笔粗细
      this.textFontSize = 25; // 默认字体大小 16px
      this.isClear = false;
      this.isEraserUse = false;
      this.isPrev = false;
      this.isNext = false;
      this.isColorChooseUse = false;
      this.isBrushOpen = false; // 笔刷选择器控制canvas打开或者关闭
      this.isShapeUse = false;
      this.isInsertText = false;
      this.isMouseClick = true;
      this.shapeType = 0; // 图形种类, 0 方形 1  圆形 2 三角形
      // }
    },
    // 处理全局画板
    handleGlobalBoard(val, type) {
      console.log("页面类型", this.pageType); // 0 全局  2 白板页面
      if (!this.pageType) {
        this.$bus.$emit("openPageWhiteBoard");
      } else {
        this.$bus.$emit("OpenGlobalWhiteBoard", val);
      }
    },
    // 初始化按钮展示
    initToolBarButton() {
      this.isMouseClick = false;
      this.isBrushOpen = false;
      this.isInsertText = false;
      this.isShapeUse = false;
      this.isEraserUse = false;
      this.isPointClick = false;
      this.isShowSelectBox = false;
      this.isColorChooseUse = false;
    },

    // 打开鼠标选择
    handleClickMouse(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickMouseSocketInfo = {
          value: Math.random(),
        };
      }
      this.$bus.$emit("controlCanvas", "clear");
      // this.$bus.$emit("canvasON", false);
      this.handleGlobalBoard(false, 0);

      this.initToolBarButton();
      this.isMouseClick = true;
    },

    //  处理画线条
    handleDrawLine(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickDrawLineSocketInfo = {
          value: Math.random(),
        };
      }
      this.initToolBarButton();
      this.isBrushOpen = true;
      console.log("用笔状态:", this.isBrushOpen);
      this.handleGlobalBoard(true);
      this.drawColor = "#cd4c3f";
      setTimeout(() => {
        this.$bus.$emit("chooseLine", true);
      }, 50);
    },

    // 处理插入文字
    handleInsertWords(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickInsertWordsSocketInfo = {
          value: Math.random(),
        };
      }
      this.initToolBarButton();
      this.isInsertText = true;

      this.handleGlobalBoard(true);
      setTimeout(() => {
        this.$bus.$emit("chooseText", this.textFontSize);
      }, 50);
    },

    // 处理形状选择
    handleChooseShape(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickChooseShapeSocketInfo = {
          value: Math.random(),
        };
      }

      this.initToolBarButton();
      this.isShapeUse = true;

      this.handleGlobalBoard(true);
      setTimeout(() => {
        this.shapeType = 0;
        this.$bus.$emit("chooseShape", 0);
      }, 50);
    },

    // 使用橡皮擦
    handleEraser(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickUseEraserSocketInfo = {
          value: Math.random(),
        };
      }
      this.initToolBarButton();
      this.isEraserUse = true;
      setTimeout(() => {
        this.$bus.$emit("chooseEraser", true);
      }, 50);
    },

    // 处理清除 前进 后退
    handleControl(action, isFromSocket = false) {
      console.log("处理action");
      if (!isFromSocket) {
        this.clickControlActionSocketInfo = {
          action,
          value: Math.random(),
        };
      }
      if (action === "prev") {
        this.isPrev = true;
        this.$bus.$emit("controlCanvas", action);
        setTimeout(() => {
          this.isPrev = false;
        }, 300);
      } else if (action === "next") {
        this.isNext = true;
        this.$bus.$emit("controlCanvas", action);
        setTimeout(() => {
          this.isNext = false;
        }, 300);
      } else {
        this.isClear = true;
        this.$bus.$emit("controlCanvas", action);
        setTimeout(() => {
          this.isClear = false;
        }, 300);
      }
    },

    // 打开颜色选择
    handleOpenChooseColor(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.openDrawColorSocketInfo = {
          value: Math.random(),
        };
      }
      this.isColorChooseUse = true;
    },

    // 选择颜色
    chooseColor(color, isFromSocket = false) {
      if (!isFromSocket) {
        this.chooseDrawColorSocketInfo = {
          color,
          value: Math.random(),
        };
      }
      this.$bus.$emit("changeColor", color);
      this.isColorChooseUse = false;
      this.drawColor = color;
      this.$bus.$emit("chooseDrawColor", color);
    },

    // 选择线条粗细
    chooseLineWidth(lineWidth, isFromSocket = false) {
      if (!isFromSocket) {
        this.chooseLineWidthSocketInfo = {
          lineWidth,
          value: Math.random(),
        };
      }
      this.$bus.$emit("changeLineWidth", lineWidth);
      this.$bus.$emit("chooseLineWidth", lineWidth);
      this.lineWidth = lineWidth;
    },

    // 打开字体大小选择
    openFontSelect(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.openFontSizeSocketInfo = {
          value: Math.random(),
        };
      }
      this.isShowSelectBox = true;
    },

    // 选择文字大小
    chooseTextFontSize(fontSize, isFromSocket = false) {
      if (!isFromSocket) {
        this.chooseFontSizeSocketInfo = {
          fontSize,
          value: Math.random(),
        };
      }
      this.textFontSize = fontSize;
      this.isShowSelectBox = false;
      this.$bus.$emit("chooseText", fontSize);
    },

    // 选择画图形状
    chooseShape(shapeType, isFromSocket = false) {
      if (!isFromSocket) {
        this.chooseShapeTypeSocketInfo = {
          shapeType,
          value: Math.random(),
        };
      }
      this.shapeType = shapeType;
      this.$bus.$emit("isOpenTheCanvas", true);
      this.$bus.$emit("chooseShape", shapeType);
    },

    // 点击取消颜色选择
    handleClick() {
      this.isColorChooseUse = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>