var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-title-container" },
    [
      _vm._l(_vm.pageTitleList, function (item, index) {
        return [
          index + 1 === _vm.pageIndex
            ? _c(
                "div",
                {
                  key: index + "page",
                  staticClass: "page-title",
                  class: {
                    active: _vm.pageIndex === index + 1 && _vm.titleType === 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.switchPage(0)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "font-lesson-title" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                ]
              )
            : _vm._e(),
          item.target && index + 1 === _vm.pageIndex
            ? _c(
                "div",
                {
                  key: index,
                  staticClass: "page-title",
                  class: {
                    active: _vm.pageIndex === index + 1 && _vm.titleType === 1,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.switchPage(1)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "font-lesson-title" }, [
                    _vm._v(_vm._s(item.target ? item.target : "")),
                  ]),
                ]
              )
            : _vm._e(),
        ]
      }),
      _c(
        "div",
        {
          staticClass: "page-title",
          class: { active: _vm.titleType === 2 },
          on: {
            click: function ($event) {
              return _vm.switchPage(2)
            },
          },
        },
        [
          _c("span", { staticClass: "font-lesson-title" }, [
            _vm._v("Whiteboard"),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }