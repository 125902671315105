var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header-container" }, [
    _c("div", { staticClass: "layout-lesson" }, [
      _c("div", {
        staticClass: "student-left-back",
        on: { click: _vm.backToHome },
      }),
      _c("div", { staticClass: "handle-word-wrap font-lesson-details" }, [
        _vm._v(" LESSON " + _vm._s(_vm.lessonNum) + " "),
      ]),
      _c(
        "ul",
        { staticClass: "progressBar" },
        _vm._l(_vm.pageNumTotal, function (item, index) {
          return _c(
            "li",
            { key: index },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: { effect: "dark", content: item, placement: "bottom" },
                },
                [
                  _c("span", {
                    staticClass: "progressDot",
                    class: {
                      progressDotFinish: item <= _vm.pageNum,
                      progressDotCurrent: item === _vm.pageNum,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.jumpPage(item)
                      },
                    },
                  }),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "student-right" }, [
      _c("div", { staticClass: "chat-area" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false",
              },
            ],
            staticClass: "student-chat hover-opacity",
            on: {
              click: function ($event) {
                return _vm.navShowClick()
              },
              mouseenter: function ($event) {
                _vm.isNavHover = true
              },
              mouseleave: function ($event) {
                _vm.isNavHover = false
              },
            },
          },
          [
            !_vm.isNavHover && !_vm.isShowNav
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/img/01-Menu/menu-materials.svg"),
                    alt: "",
                  },
                })
              : _vm._e(),
            _vm.isNavHover && !_vm.isShowNav
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/img/01-Menu/menu-materials-active.svg"),
                    alt: "",
                  },
                })
              : _vm._e(),
            _vm.isShowNav
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/img/01-Menu/menu-materials-active.svg"),
                    alt: "",
                  },
                })
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "student-chat hover-opacity",
            on: {
              click: function ($event) {
                return _vm.chatShowClick()
              },
              mouseenter: function ($event) {
                _vm.isChatHover = true
              },
              mouseleave: function ($event) {
                _vm.isChatHover = false
              },
            },
          },
          [
            !_vm.isChatHover && !_vm.isShowChat
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/img/01-Menu/menu-chat.svg"),
                    alt: "",
                  },
                })
              : _vm._e(),
            _vm.isChatHover && !_vm.isShowChat
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/img/01-Menu/menu-chat-active.svg"),
                    alt: "",
                  },
                })
              : _vm._e(),
            _vm.isShowChat
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/img/01-Menu/menu-chat-active.svg"),
                    alt: "",
                  },
                })
              : _vm._e(),
          ]
        ),
      ]),
      _c("div", { staticClass: "class-time-area" }, [
        _c("div", { staticClass: "class-time-content" }, [
          _c("span"),
          _c("span", { staticClass: "font-lesson-details" }, [
            _vm._v("In class | "),
          ]),
          _c("span", { staticClass: "font-lesson-details" }, [
            _vm._v(_vm._s(_vm._f("secTOmin")(_vm.countDown))),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }