<template>
  <div class="interaction-container">
    <audio :src="songUrl" ref="bonusStarSound"></audio>
    <!-- video -->

    <div class="video-content">
      <!-- video list -->
      <!-- 老师 -->
      <div
        class="content remote-video"
        v-for="(x, index) in remoteStreamList"
        :key="index"
        v-show="false"
      >
        <!-- name -->
        <p class="nickname">{{ x.nickname }}</p>
        <!-- @click="checkCamera" -->
        <div class="icon_camera" @click="handleRemoteCamera">
          <img
            src="@/assets/img/19-Dashboard/icon-camera-blue.svg"
            alt=""
            v-if="isRemoteVideoOpen"
          />
          <img
            src="@/assets/img/19-Dashboard/icon-camera-blue-off.svg"
            alt=""
            v-else
          />
        </div>
        <div class="icon_micro" @click="handleRemoteMicro">
          <img
            src="@/assets/img/19-Dashboard/icon-micro-on.svg"
            v-if="isRemoteMicroOpen"
          />
          <img src="@/assets/img/19-Dashboard/icon-micro-off.svg" v-else />
        </div>
        <!-- video -->
        <div id="remote" class="videoCont"></div>
      </div>

      <div class="content remote-video">
        <div class="local-video-content">
          <!-- name -->
          <p class="nickname">测试</p>
          <!-- @click="checkCamera" -->
          <div class="icon_camera" @click="handleRemoteCamera">
            <img
              src="@/assets/img/19-Dashboard/icon-camera-blue.svg"
              alt=""
              v-if="isRemoteVideoOpen"
            />
            <img
              src="@/assets/img/19-Dashboard/icon-camera-blue-off.svg"
              alt=""
              v-else
            />
          </div>
          <div class="icon_micro" @click="handleRemoteMicro">
            <img
              src="@/assets/img/19-Dashboard/icon-micro-on.svg"
              v-if="isRemoteMicroOpen"
            />
            <img src="@/assets/img/19-Dashboard/icon-micro-off.svg" v-else />
          </div>
          <!-- video -->
          <div id="remote" class="videoCont"></div>
        </div>
      </div>

      <!-- 自己 -->
      <div class="content local-video">
        <div class="student-star">
          <span>
            <img
              :class="{ animatedstar: clickedOnStar }"
              src="@/assets/img/07-Stars/star-big.svg"
              alt=""
            />
            <img
              v-if="clickedOnStar === 1"
              src="@/assets/img/07-Stars/star-big.svg"
              alt=""
            />
          </span>
          <span>{{ starNum }}</span>
        </div>
        <p class="nickname">You</p>
        <div class="local-video-content">
          <!-- camera -->
          <div class="icon_camera" @click="handleLocalCamera">
            <img
              src="@/assets/img/19-Dashboard/icon-camera-blue.svg"
              alt=""
              v-if="isVideoOpen"
            />
            <img
              src="@/assets/img/19-Dashboard/icon-camera-blue-off.svg"
              alt=""
              v-else
            />
          </div>
          <div class="icon_micro" @click="handleLocalMicro">
            <img
              src="@/assets/img/19-Dashboard/icon-micro-on.svg"
              v-if="isMicroOpen"
            />
            <img src="@/assets/img/19-Dashboard/icon-micro-off.svg" v-else />
          </div>

          <!-- video -->
          <div id="local" class="videoCont"></div>
        </div>
      </div>

      <!-- modal 选择摄像头-->
      <!-- <div v-show="modalType" class="modal" @dblclick.self="modalType = null">
        <SelectCamera
          v-if="modalType == 1"
          @close="modalType = null"
          @selectCamera_confirm="selectCamera_confirm"
        ></SelectCamera>
      </div> -->
    </div>

    <!-- course -->

    <div class="student_nav" v-if="isShowNav">
      <div class="nav_header">
        <p @click="closeNav">X</p>
        <p>Content</p>
      </div>
      <div class="nav_container">
        <div class="nav_course_one">
          <div class="nav_course">
            <span>LEVEL 1</span>
            <span> | </span>
            <span> 我的学校</span>
          </div>
          <div
            v-for="(item, index) in lessonList"
            :key="index"
            class="nav_lesson"
            :class="{ active: currentLessonIndex === item.lessonId }"
            @click="jumpLesson(item.lessonId)"
          >
            <div class="nav_lesson_title">
              {{ item.lessonName }}
            </div>
            <p
              class="nav_lesson_status red_color"
              v-if="currentLessonIndex === item.lessonId"
            >
              CURRENT
            </p>
          </div>
        </div>
        <div class="add_new_nav" @click="typeAdd_click()">
          <p>Add new</p>
          <p>+</p>
        </div>
      </div>
    </div>

    <!-- chatting -->

    <div class="chat_nav" v-if="isShowChat">
      <div class="nav_header">
        <p @click="closeChat">X</p>
        <p>Chat</p>
      </div>
      <!-- 上课面板 -->
      <div class="chat_container">
        <!-- 线上课互动面板 -->
        <div v-if="classType == 1" class="interactContent">
          <!-- 主要内容 -->
          <div
            class="interactContent_main"
            v-bind:style="{ width: '100%', height: '100%' }"
          >
            <!-- chat -->
            <Chat ref="chatkids" v-show="interactType == 1" class="chat"></Chat>
            <!-- particopants -->
            <Particopants
              v-show="interactType == 2"
              class="particopants"
            ></Particopants>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// socket sendMessage
// import TRTC from "trtc-js-sdk";
let appId, userSig;
let userId = JSON.parse(localStorage.userData).userSetting.userId;
console.log(userId);

// socket
let socket = null;
let socketCode = null;
let socketurl = "wss://api.nihaocafe.com/SmartLingo/communication";
import {
  getMessage_10004003,
  getMessage_10005,
  getMessage_10005001,
  getMessage_10006006,
  getMessage_10006007,
  getMessage_10006008,
  getMessage_10006009,
  getMessage_100060011,
  getMessage_100060012,
  getMessage_1000600122,
  getMessage_100060013,
  getMessage_100060014,
} from "@/utils/socketGetMessage";

// api
import { sendMessage_10005001 } from "@/utils/socketSendMessage";

// 国外y音视频服务商

import { getTwilioToken } from "@/assets/js/axios/kidsApi.js";
const { connect, createLocalTracks } = require("twilio-video");
const Video = require("twilio-video");
import Chat from "./Chat";
import Particopants from "./Particopants";

export default {
  components: {
    Chat,
    Particopants,
  },
  props: {
    authority: {
      //权限开关
      type: Boolean,
      require: true,
    },
  },
  filters: {
    filter_camera(str) {
      let icon = require(`@/assets/img/19-Dashboard/${str}.svg`);
      return icon;
    },
  },
  computed: {
    classType() {
      /**
       * 上课类型
       * 1 线上
       * 2 线下
       * **/
      let type = 2;
      if (this.courseData.isOnline == 1) {
        type = 1;
      }
      return type;
    },
  },
  data() {
    return {
      roomid: null,
      currentLessonIndex: null,
      lessonList: [
        { lessonName: "你好   我是冬冬. (上)", lessonId: 1 },
        { lessonName: "你好   我是冬冬. (下)", lessonId: 2 },
        // { lessonName: "你好   我是西西. (上)", lessonId: 3 },
        // { lessonName: "你好   我是西西. (下)", lessonId: 4 },
        // { lessonName: "你好   我是南南. (上)", leeeonId: 5 },
      ],
      interactType: 1,
      // 是否显示漂浮白板
      showWhiteboardFloat: false,
      whiteboardTabActive: false,
      show: 1,
      isShowNav: false,
      isShowChat: false,
      courseData: {},
      showRoom: false,
      rtcSource: "tx",
      remoteStreamList: [],

      isVideoOpen: true,
      isMicroOpen: true,
      client: null,
      localStream: null,
      remoteStream: null,
      isRemoteVideoOpen: true,
      isRemoteMicroOpen: true,

      /**
       * modal type
       * 1 选择摄像头
       * **/
      modalType: null,
      clickedOnStar: 0,
      starNum: 0,
      songUrl: "",

      // 国外音视频服务商
      twilioRoom: null,
    };
  },
  mounted() {
    console.log(process.env);
    if (process.env.NODE_ENV === "dev") {
      // 测试服和本地用国外音视频服务商
      this.closeRemoteTracks();
      this.initTwilio();
      this.openLocaltracks();
    }
    this.initData();

    // 注册进入房间事件
    this.$bus.$on("realTimeVideo_enterRoom", this.realTimeVideo_enterRoom);
    // 注册退出房间事件
    this.$bus.$on("realTimeVideo_leaveRoom", this.realTimeVideo_leaveRoom);
    // 注册操作视频点击事件
    this.$bus.$on(
      "realTimeVideo_group_optionVideo",
      this.realTimeVideo_group_optionVideo
    );
    // 注册操作声音点击事件
    this.$bus.$on(
      "realTimeVideo_group_optionPhone",
      this.realTimeVideo_group_optionPhone
    );
    // 注册点击展示chatting窗口事件
    this.$bus.$on("showChatting", () => {
      this.isShowNav = false;
      this.isShowChat = true;
    });
    // 注册点击展示课程nav窗口事件
    this.$bus.$on("showCourseNav", () => {
      console.log("打开聊天");
      this.isShowNav = true;
      this.isShowChat = false;
    });
    // 改变星星num
    this.$bus.$on("interactionStars", (value) => {
      if (value > this.starNum) {
        this.increaseStar();
      } else {
        this.decreaseStar();
      }
    });
  },
  created() {
    this.roomid = parseInt(this.$route.params.calendarEventId);
    // console.log(this.roomid);
    this.currentLessonIndex = parseInt(this.$route.name.split("Lesson")[1]);
  },
  watch: {
    $route(value) {
      this.currentLessonIndex = parseInt(value.name.split("Lesson")[1]);
      // console.log(this.currentLessonIndex);
    },
  },
  methods: {
    increaseStar() {
      this.clickedOnStar = 1;
      this.starNum++;
      setTimeout(() => {
        this.clickedOnStar = 0;
      }, 1000);

      this.songUrl = require("@/assets/audio/yipi.mp3");
      this.$refs.bonusStarSound.play();
    },
    decreaseStar() {
      // this.clickedOnStar = 1;
      this.starNum--;
      if (this.starNum <= 0) {
        this.starNum = 0;
      }
      setTimeout(() => {
        this.clickedOnStar = 0;
      }, 1000);
    },
    jumpLesson(index) {
      this.$router.push({
        name: `Lesson${index}`,
      });
    },

    /**
     * @description: 初始化国外服务商
     * @return {*}
     */
    async initTwilio() {
      console.log("初始化国外服务商");
      const name = this.$route.query.calendarEventId + "kids";
      // 获取令牌
      const res = await getTwilioToken();
      if (!res || !res.data) {
        return;
      }
      const token = res.data;

      // 连接到房间
      connect(token, { name }).then(
        (room) => {
          console.log(`加入房间成功:`, room, Date.now());
          this.twilioRoom = room;
          // this.changeStudentServer(this.isOverseaServer);

          // 其他人进入房间的事件
          room.on("participantConnected", (participant) => {
            console.log(`有人加入了：`, participant, Date.now());
            this.closeRemoteTracks();

            // 获得推流
            participant.on("trackSubscribed", (track) => {
              document.getElementById("remote").appendChild(track.attach());
            });
          });
          // 获得已经进入房间的tracks
          room.participants.forEach((participant) => {
            // console.log(
            //   `Participant "${participant.identity}" is connected to the Room`
            // );
            console.log("获得已经进入房间的tracks");
            this.closeRemoteTracks();
            participant.on("trackSubscribed", (track) => {
              document.getElementById("remote").appendChild(track.attach());
            });
          });
          // 其他人离开房间时的事件
          room.on("participantDisconnected", (participant) => {
            // console.log(`Participant disconnected: ${participant.identity}`);
          });
          // 自己离开房间
          room.on("disconnected", (room) => {
            // Detach the local media elements
            // console.log("自己离开房间");
            room.localParticipant.tracks.forEach((publication) => {
              const attachedElements = publication.track.detach();
              attachedElements.forEach((element) => element.remove());
            });
          });
        },
        (error) => {
          console.error(`Unable to connect to Room: ${error.message}`);
        }
      );
    },

    /**
     * @description:  打开本地音视频轨道
     * @return {*}
     */
    openLocaltracks() {
      console.log("打开本地音视频轨道");
      Video.createLocalTracks().then(function (localTracks) {
        // console.log(localTracks);
        // 展示本地音视频
        const localMediaContainer = document.getElementById("local");
        localTracks.forEach(function (track) {
          localMediaContainer.appendChild(track.attach());
        });
      });
    },
    /**
     * @description:  关闭远程推流
     * @return {*}
     */
    closeRemoteTracks() {
      console.log("关闭远程推流");
      const otherMediaContainer = document.getElementById("remote");
      if (otherMediaContainer) {
        otherMediaContainer.innerHTML = "";
      }
    },
    /**
     * @description:  关闭远程推流
     * @return {*}
     */
    closeLocalTracks() {
      console.log("关闭本地推流");
      const localMediaContainer = document.getElementById("local");
      if (localMediaContainer) {
        localMediaContainer.innerHTML = "";
      }
    },

    /**
     * 腾讯
     * **/

    // 初始化音视频客户端对象
    tw_initClient() {
      // userId = parseInt(Math.random() * 100000000) + '';
      const config = genTestUserSig(userId.toString());
      appId = config.sdkAppId;
      userSig = config.userSig;
      if (this.client) {
        return false;
      }
      // console.log(userId)
      this.client = TRTC.createClient({
        sdkAppId: appId,
        userId: userId.toString(),
        userSig: userSig,
        mode: "rtc",
      });
      this.client.on("stream-added", (event) => {
        const remoteStream = event.stream;
        console.log("远端流增加: " + remoteStream.getId());

        //订阅远端流
        this.client.subscribe(remoteStream);
      });
      this.client.on("stream-subscribed", (event) => {
        this.remoteStream = event.stream;
        console.log("远端流订阅成功：" + this.remoteStream.getId());

        // 播放远端流
        this.remoteStream.play("remote");
      });

      // 进房
      this.client
        .join({ roomId: this.roomid })
        .catch((error) => {
          console.error("进房失败 " + error);
        })
        .then(() => {
          console.log("进房成功");
          this.localStream = TRTC.createStream({
            userId: userId.toString(),
            audio: true,
            video: true,
          });

          this.localStream
            .initialize()
            .catch((error) => {
              console.error("初始化本地流失败 " + error);
            })
            .then(() => {
              console.log("初始化本地流成功");
              // 播放本地流
              this.localStream.play("local");
              // 发布本地流
              this.client
                .publish(this.localStream)
                .catch((error) => {
                  console.error("本地流发布失败 " + error);
                })
                .then(() => {
                  console.log("本地流发布成功");
                });
            });
        });
    },

    // 退出房间
    tx_leaveRoom() {
      this.client
        .leave()
        .then(() => {
          console.log("退房成功");
          // 退房成功，可再次调用client.join重新进房开启新的通话。
        })
        .catch((error) => {
          console.error("退房失败 " + error);
          // 错误不可恢复，需要刷新页面。
        });
    },
    /**
     * 腾讯 end
     * **/

    // 远程摄像头处理
    handleRemoteCamera(item) {
      this.isRemoteVideoOpen = !this.isRemoteVideoOpen;
      if (this.isRemoteVideoOpen) {
        //开启视频
        // this.localStream.play('local')
        this.remoteStream.unmuteVideo();
      } else {
        //关闭视频
        this.remoteStream.muteVideo();
      }
    },
    // 远程语音处理
    handleRemoteMicro() {
      this.isRemoteMicroOpen = !this.isRemoteMicroOpen;
      if (this.isRemoteMicroOpen) {
        //开启视频
        this.remoteStream.unmuteAudio();
      } else {
        //关闭视频
        this.remoteStream.muteAudio();
      }
    },
    // 本地摄像头处理
    handleLocalCamera() {
      this.isVideoOpen = !this.isVideoOpen;
      console.log(this.isVideoOpen);
      if (this.isVideoOpen) {
        //开启视频
        // this.localStream.play('local')
        if (process.env.NODE_ENV === "dev") {
          if (!this.twilioRoom) {
            return;
          }
          this.openLocaltracks();
          this.twilioRoom.localParticipant.videoTracks.forEach(
            (publication) => {
              publication.track.enable();
            }
          );
        } else {
          this.localStream.unmuteVideo();
        }
      } else {
        //关闭视频
        console.log("关闭视频");
        if (process.env.NODE_ENV === "dev") {
          if (!this.twilioRoom) {
            return;
          }
          this.closeLocalTracks();
          this.twilioRoom.localParticipant.videoTracks.forEach(
            (publication) => {
              publication.track.disable();
            }
          );
        } else {
          this.localStream.muteVideo();
        }
      }
    },
    // 本地语音处理
    handleLocalMicro() {
      this.isMicroOpen = !this.isMicroOpen;
      if (this.isMicroOpen) {
        //开启视频
        if (process.env.NODE_ENV === "dev") {
          if (!this.twilioRoom) {
            return;
          }
          this.twilioRoom.localParticipant.audioTracks.forEach(
            (publication) => {
              publication.track.enable();
            }
          );
        } else {
          this.localStream.unmuteAudio();
        }
      } else {
        //关闭视频
        if (process.env.NODE_ENV === "dev") {
          if (!this.twilioRoom) {
            return;
          }
          this.twilioRoom.localParticipant.audioTracks.forEach(
            (publication) => {
              publication.track.disable();
            }
          );
        } else {
          this.localStream.muteAudio();
        }
      }
    },

    // 原来数据初始
    initData() {
      // console.log(this.courseData)
      this.courseData.isOnline = 1;
      this.courseData.calendarEventId = this.$route.params.calendarEventId;
      // console.log(this.courseData.calendarEventId);
      this.remoteStreamList = [];
      this.courseData.teacherOVList = [
        {
          id: 26843,
          nickname: "张昌怡 | Zhāng Chāngyí",
        },
      ]; // 假数据
      this.courseData.teacherOVList.forEach((val) => {
        this.remoteStreamList.push({
          id: val.id,
          nickname: val.nickname,
        });
      });

      // 初始化soket
      this.socketInit();
      this.tw_initClient();
    },

    /**
     * websocket
     * **/
    // websocket init
    socketInit() {
      if (typeof WebSocket === "undefined") {
        this.$message({
          type: "error",
          message: "您的浏览器不支持socket",
        });
      } else {
        // socket实例化
        let user = JSON.parse(localStorage.userData);
        let url = `${socketurl}/${user.userSetting.userId}/${this.courseData.calendarEventId}`;
        console.log("socket实例化", url);
        socket = new WebSocket(url);
        // console.log(socket)
        // return
        //  监听open事件
        socket.onopen = this.open;
        // 监听error事件
        socket.onerror = this.error;
        // 监听close事件
        socket.onclose = this.close;
        // 监听message消息
        socket.onmessage = this.getMessage;
      }
    },
    open() {
      // 事件中心注册发送消息事件
      this.$bus.$on("socket_sendMessage", this.sendMessage);
      console.log("socket连接成功");
      let userData = JSON.parse(localStorage.userData);
      sendMessage_10005001({
        text: `${userData.userSetting.nickname} JOINED THE CLASSROOM`,
      });
      // 判断是否为老师，是则调用老师签到接口
      // updateTeacherSignedTime({
      //   calendarEventId: this.courseData.calendarEventId,
      // }).then((res) => {
      //   // console.log(res);
      //   console.log("老师签到");
      // });
    },
    error() {
      console.log("连接错误");
      // this.websocket_reconnet();
    },
    close() {
      // 解绑
      this.$bus.$off("socket_sendMessage");
      console.log("socket已经关闭", socketCode);
      if (socketCode != "leave") {
        this.websocket_reconnet();
      }
    },
    getMessage(msg) {
      let data = JSON.parse(msg.data);
      // console.log("getmessage", data);
      switch (Number(data.type)) {
        case 10004003:
          getMessage_10004003(data);
          break;
        case 10005:
          getMessage_10005(data);
          if (this.whiteboardTabActive == true) {
            this.unreadChatMessages++;
          }
          break;
        case 10005001:
          getMessage_10005001(data);
          break;
        case 10005002:
          this.message_10005002(data);
          break;
        case 10006006:
          getMessage_10006006(data);
          break;
        case 10006007:
          getMessage_10006007(data);
          break;
        case 10006008:
          getMessage_10006008(data);
          break;
        case 10006009:
          getMessage_10006009(data);
          break;
        case 100060011:
          getMessage_100060011(data);
          break;
        case 100060012:
          getMessage_100060012(data);
          break;
        case 1000600122:
          getMessage_1000600122(data);
          break;
        case 100060013:
          getMessage_100060013(data);
          break;
        case 100060014:
          getMessage_100060014(data);
          break;
      }
    },
    // 10005002消息解析
    message_10005002(data) {
      let text = "LEAVE THE CLASSROOM";
      for (let [index, student] of this.courseData.teacherOVList.entries()) {
        if (student.id == data.from) {
          text = `${student.nickname} LEAVE THE CLASSROOM`;
          break;
        }
      }

      data.data = {
        text: text,
      };
      getMessage_10005001(data);
    },
    sendMessage(params) {
      // console.log(params)
      params.classroomid = this.courseData.calendarEventId;
      socket.send(JSON.stringify(params));
    },
    websocket_reconnet() {
      socket = null;
      setTimeout(() => {
        this.socketInit();
      }, 1000 * 10);
    },
    /** socket end **/

    // 关闭nav窗口
    closeNav() {
      this.isShowNav = false;
      this.$bus.$emit("closeNav");
    },
    // 关闭chat窗口
    closeChat() {
      this.isShowChat = false;
      this.$bus.$emit("closeChat");
    },
  },
  beforeDestroy() {
    this.$bus.$off("showChatting");
    this.$bus.$off("showCourseNav");
    console.log("++++++++++++++");
    // 取消发布本地流
    this.client.unpublish(this.localStream).then(() => {
      console.log("取消发布本地流成功");
      // 取消发布本地流成功
      this.tx_leaveRoom();
    });
    navigator.mediaDevices
      .getUserMedia({
        audio: true, //音频轨道
        video: true,
      })
      .then((stream) => {
        stream.getTracks().forEach((item) => {
          // console.log(item, item.stop());
          item.stop();
        });
      });
  },
};
</script>

<style scoped lang="scss">
.interaction-container {
  width: 100%;
  height: 100%;
  position: relative;

  .video-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    box-sizing: border-box;
    position: relative;

    .local-video {
      margin-top: 5px;
      margin-bottom: 0 !important;
    }
    .content {
      width: 100%;
      height: calc(50% - 5px);
      border-radius: 20px;
      // overflow: hidden;
      background-color: #ffffff;
      position: relative;
      // margin-bottom: 5px;
      &:hover {
        .option {
          max-height: 150px;
          padding: 10px 20px;
        }
      }
      .local-video-content {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        overflow: hidden;
        background-color: #ffffff;
        position: relative;
      }
      .icon_camera,
      .icon_micro {
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .icon_camera {
        position: absolute;
        bottom: 10px;
        right: 50px;
        z-index: 2;
      }
      .icon_micro {
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 2;
      }
      .nickname {
        position: absolute;
        text-align: right;
        color: #ffffff;
        font-size: 12px;
        background-color: #cd4c3f;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        padding: 7px 25px;
        bottom: 0px;
        left: 20px;
        z-index: 2;
      }
      .option {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0px 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 2;
        background-color: rgba($color: #000000, $alpha: 0.5);
        max-height: 0px;
        transition: all 0.5s;
        overflow: hidden;
        .new_img {
          margin: 0 5px;
        }
        .icon_video {
          width: 20px;
          height: 20px;
        }
        .icon_phone {
          width: auto;
          height: 30px;
        }
      }
      .videoCont {
        z-index: 1;
        width: 100%;
        height: 100%;
      }
    }
    .local-video {
      position: relative;
      .student-star {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 105px;
        padding: 0% 3.5%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 35px;
        cursor: default;
        height: 3.5em;
        // very large screen
        z-index: 100;

        @media screen and (max-width: 1500px) {
          // laptop
          height: 2.5em;
          width: 80px;
        }

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30%;
          color: #224e96;
          font-size: 1.2em;
          font-weight: 600;
          font-family: "GenSenRoundedTW" !important;
          padding: 0 5px;
          img {
            max-width: 25px;
            z-index: 99999;
          }

          // very large screen

          @media screen and (max-width: 2200px) {
            // external screen
          }
          @media screen and (max-width: 1500px) {
            // laptop
          }
          @media screen and (max-width: 1300px) {
            // ipad
          }
        }
        span:nth-child(1) {
          width: 30%;
          color: #224e96;
          font-size: 13px;
          font-family: "GenSenRoundedTW";
          text-align: center;
          line-height: 30px;
          padding: 3px;

          img {
            width: auto;

            height: 3.5em;
            // very large screen

            @media screen and (max-width: 2200px) {
              // external screen
            }
            @media screen and (max-width: 1500px) {
              // laptop
              // height: 2.5em;
              height: 1em;
            }
            @media screen and (max-width: 1300px) {
              // ipad
            }

            &.animatedstar {
              -webkit-animation: spin 1s linear infinite;
              -moz-animation: spin 1s linear infinite;
              animation: spin 1s linear infinite;
              -webkit-animation-iteration-count: 1;
            }
          }
        }
        span:nth-child(3) {
          width: 30%;
          color: #224e96;
          font-family: "GenSenRoundedTW";
          text-align: center;

          font-weight: 600;
        }

        .addStar,
        .removeStar {
          &:hover {
            color: #f4d737;
          }
          padding: 0 5px;
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
  }
  .chat_nav {
    // position: fixed;
    // right: 10px;
    // bottom: 0;
    // width: 35%;
    // height: calc(100% - 54px);

    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;

    background: #0e2e62;
    z-index: 1004;
    .chat_container {
      width: 100%;
      height: calc(100% - 100px);
      // overflow: scroll;
      .interactContent {
        height: 100%;
        .interactContent_switchbar {
          height: 100%;
        }
      }
    }
  }
  .student_nav {
    position: fixed;
    right: 10px;
    bottom: 0;
    width: 35%;
    opacity: 0.96;
    height: calc(100% - 54px);
    background: #0e2e62;
    z-index: 1004;
    .nav_container {
      margin-left: 9%;
      margin-right: 10%;
      color: #fff;
      .nav_course_one {
        margin-bottom: 40px;
      }
      .nav_course {
        font-family: Yuanti SC-Bold;
        margin-bottom: 18px;
      }
      .nav_lesson {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        background: #375075;
        border-radius: 9px;
        padding: 16px 5% 16px 6%;
        color: #fff;
        margin-bottom: 8px;
      }
      .active {
        background: #dadfe6;
        color: #1b4589;
      }
      .red_color {
        font-family: Yuanti SC-Bold;
        color: #cd4c3f;
      }
      .add_new_nav {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        background: #224e96;
        padding: 16px 5% 16px 6%;
        border-radius: 9px;
      }
    }
  }
  .nav_header {
    font-family: Yuanti SC-Bold;
    margin-left: 5%;
    margin-right: 10%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    color: #fff;
    margin-bottom: 40px;
    p {
      cursor: pointer;
    }
  }
}

.interactContent_main {
  flex: 1;
  height: 10px;
  overflow: hidden;
  .chat,
  .particopants {
    width: 100%;
    height: 100%;
    // border-radius: 0 0 30px 30px;
  }
}

@media screen and (max-width: 1440px) {
  .REALTIMEVIDEO {
    .content {
      width: 100%;
    }
  }
}
// spin animation
// spin animation
@-moz-keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
    -moz-transform: scale(1) rotate(0deg);
    position: absolute;
    left: 45px;
    top: 3px;
  }
  20% {
    -moz-transform: scale(3.5) rotate(90deg);
    transform: scale(1.5) rotate(60deg);
    position: absolute;
  }
  80% {
    opacity: 0.8;
    position: absolute;
  }
  100% {
    -moz-transform: scale(30.5) rotate(360deg);
    transform: scale(30.5) rotate(360deg);
    top: 20vh;
    left: -700px;
    position: absolute;
    opacity: 0;
  }
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
    -moz-transform: scale(1) rotate(0deg);
    position: absolute;
    left: 45px;
    top: 3px;
  }
  20% {
    -moz-transform: scale(3.5) rotate(90deg);
    transform: scale(1.5) rotate(60deg);
    position: absolute;
  }
  80% {
    opacity: 0.8;
    position: absolute;
  }
  100% {
    -moz-transform: scale(30.5) rotate(360deg);
    transform: scale(30.5) rotate(360deg);
    top: 20vh;
    left: -700px;
    position: absolute;
    opacity: 0;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
    -moz-transform: scale(1) rotate(0deg);
    position: absolute;
    left: 45px;
    top: 3px;
  }
  20% {
    -moz-transform: scale(3.5) rotate(90deg);
    transform: scale(1.5) rotate(60deg);
    position: absolute;
  }
  80% {
    opacity: 0.8;
    position: absolute;
  }
  100% {
    -moz-transform: scale(30.5) rotate(360deg);
    transform: scale(30.5) rotate(360deg);
    top: 20vh;
    left: -700px;
    position: absolute;
    opacity: 0;
  }
}
</style>
