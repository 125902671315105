var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CHAT" },
    [
      _c(
        "div",
        {
          ref: "messageList",
          staticClass: "main customScrollbar",
          on: {
            dragover: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
            },
            dragleave: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
            },
            dragenter: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
            },
            drop: function ($event) {
              $event.preventDefault()
              return _vm.dropImg.apply(null, arguments)
            },
          },
        },
        _vm._l(_vm.messageList, function (message, index) {
          return _c("MessageKids", {
            key: index,
            attrs: { messageData: message },
          })
        }),
        1
      ),
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "foot" }, [
        _c(
          "div",
          {
            staticClass: "uploadImg",
            on: {
              click: function ($event) {
                return _vm.addImage()
              },
            },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  id: "Icon_ionic-ios-images",
                  "data-name": "Icon ionic-ios-images",
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "28.434",
                  height: "28.424",
                  viewBox: "0 0 28.434 28.424",
                },
              },
              [
                _c("path", {
                  attrs: {
                    id: "路径_32313",
                    "data-name": "路径 32313",
                    d: "M17.752,13.99a.3.3,0,0,0-.489-.044L11.432,21.7a.3.3,0,0,0,.2.489l10.223.888a.3.3,0,0,0,.286-.451Z",
                    transform: "translate(-3.138 -3.379)",
                    fill: "#fff",
                  },
                }),
                _c("path", {
                  attrs: {
                    id: "路径_32314",
                    "data-name": "路径 32314",
                    d: "M25.588,18.553a.3.3,0,0,0-.489-.044L23.526,20.1a.3.3,0,0,0-.032.343l1.612,3.141a.3.3,0,0,0,.235.146l2.849.247a.3.3,0,0,0,.286-.451Z",
                    transform: "translate(-4.317 -3.824)",
                    fill: "#fff",
                  },
                }),
                _c("path", {
                  attrs: {
                    id: "路径_32315",
                    "data-name": "路径 32315",
                    d: "M26.42,15.454a1.5,1.5,0,1,0-1.5-1.783A1.512,1.512,0,0,0,26.42,15.454Z",
                    transform: "translate(-4.457 -3.243)",
                    fill: "#fff",
                  },
                }),
                _c("path", {
                  attrs: {
                    id: "路径_32316",
                    "data-name": "路径 32316",
                    d: "M29.1,8.424l-4.175-.362-.387-4.252A1.714,1.714,0,0,0,22.67,2.256L3.817,3.957A1.721,1.721,0,0,0,2.256,5.822l1.7,18.815a1.714,1.714,0,0,0,1.872,1.555l1-.089-.1,1.06A1.716,1.716,0,0,0,8.3,29.022l18.866,1.644A1.721,1.721,0,0,0,29.029,29.1l1.65-18.821A1.729,1.729,0,0,0,29.1,8.424ZM5.778,19.3a.437.437,0,0,1-.47-.387L4.166,6.222a.427.427,0,0,1,.387-.463L22.271,4.166a.437.437,0,0,1,.47.387l.3,3.351-12.8-1.123A1.721,1.721,0,0,0,8.38,8.342L7.434,19.148Zm22.984-8.6-1.11,12.691a.432.432,0,0,1-.463.393l-1.18-.1L9.465,22.239a.432.432,0,0,1-.393-.463l.241-2.786.869-9.906a.432.432,0,0,1,.463-.393l12.564,1.1,1.885.165,3.274.286A.414.414,0,0,1,28.762,10.7Z",
                    transform: "translate(-2.25 -2.249)",
                    fill: "#fff",
                  },
                }),
              ]
            ),
          ]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.message_text,
              expression: "message_text",
            },
          ],
          ref: "count",
          staticClass: "chat_input",
          attrs: {
            type: "text",
            placeholder: "Type a message…",
            maxlength: "1000",
          },
          domProps: { value: _vm.message_text },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.sendMessageText.apply(null, arguments)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.message_text = $event.target.value
            },
          },
        }),
      ]),
      _c("NewSelect2", {
        ref: "select",
        staticClass: "select",
        attrs: { listData: _vm.listData, activeIndex: _vm.networkIndex },
        on: { selectClick: _vm.selsecChange },
      }),
      _c("input", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        ref: "input_image",
        attrs: { type: "file", accept: "image/*" },
        on: { change: _vm.input_image_change },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }