import axios from './axios'

export function getLevelZeroLesson (lessonNo){//通过lesson number获取数据
    return axios({
        method: 'get',
        url: `prepareLessons/getLevelZeroLesson/${lessonNo}`,
    })
  }
export function axios_commonFun(data){
    return axios(data);
}


export function deleteNotificationRead(params){
    return axios({
        method: 'POST',
        url: 'notification/deleteNotificationRead',
        data: params
    })
}

export function setNotificationRead(params){
    return axios({
        method: 'POST',
        url: 'notification/setNotificationRead',
        data: params
    })
}

export function setNotificationReadAll(data){
    return axios({
        method: 'GET',
        url: 'notification/setNotificationReadAll',
        data: data
    })
}


export function setNotificationUnread(data){
    return axios({
        method: 'GET',
        url: 'notification/setNotificationUnread',
        data: data
    })
}

//get All notifications 
export function getNotification(params)
{
    return axios({
        method: 'post',
        url: 'notification/selectNotification',
        data: {}
    })
   

    
}

// pinyin stuff
export function getLessonContents (data){
    return axios({
        method: 'post',
        url: 'pinyin/getLessonContent',
        data
    })
  }

/**\
 * weChatManage
 * **/ 
// 获取用户微信信息
export function wx_login(params){
    return axios({
        method: 'post',
        url: 'weChatLogin/login',
        data: params
    })
}

/**
 * login
 * **/
// 获取注册验证码
export function getUserRegisterCode(params){
    return axios({
        method: 'get',
        url: 'login/getUserRegisterCode',
        params: params
    })
}
// 用户注册用户名验证（邮箱）
export function checkUserNameExisted(params){
    return axios({
        method: 'get',
        url: 'login/checkUserNameExisted',
        params: params
    })
}
// 用户注册
export function userRegister(data){
    return axios({
        method: 'POST',
        url: 'login/userRegister',
        data: data
    })
}
// 用户登录
export function userLogin(data){
    return axios({
        method: 'POST',
        url: 'login/userLogin',
        data: data
    })
}
// 根据用户id获取用户信息，切换用户接口
export function getUserById(data){
    return axios({
        method: 'POST',
        url: 'login/loginByUserId',
        data: data
    })
}
// 用户修改密码(不需验证码)
export function changeUserPwd(data){
    return axios({
        method: 'POST',
        url: 'login/changeUserPwd',
        data: data
    })
}
// 获取用户code
export function getUserCode(params){
    return axios({
        method: 'get',
        url: 'login/code',
        params: params
    })
}
// 忘记密码(获取验证码)
export function getForgetPwdCheckCode(params){
    return axios({
        method: 'get',
        url: 'login/getForgetPwdCheckCode',
        params: params
    })
}
// 验证码校验
export function checkVerificationCode(params){
    return axios({
        method: 'get',
        url: 'login/checkVerificationCode',
        params: params
    })
}
// 重置密码
export function resetPwd(data){
    return axios({
        method: 'POST',
        url: 'login/resetPwd',
        data: data
    })
}

/**
 * lesson
 * **/
// 获取用户Level列表
export function getLevelListByUser(params){
    return axios({
        method: 'get',
        url: 'lesson/getLevelListByUser',
        params: params
    })
}
// 获取所有课程
export function getLessonByUser(params){
    return axios({
        method: 'post',
        url: 'lesson/getLessonByUser',
        data: params
    })
}
// 获取用户课程章节
export function getLessonPartByUser(params){
    return axios({
        method: 'get',
        url: 'lesson/getLessonPartByUser',
        params: params
    })
}
// 获取综合练习下章节习题
export function getLessonContent(params){
    return axios({
        method: 'get',
        url: 'lesson/getLessonContent',
        params: params
    })
}
// 激活用户课程章节
export function setLessonActivation(params){
    return axios({
        method: 'post',
        url: 'lesson/setLessonActivation',
        data: params
    })
}
// 获取用户课程地图
export function getLessonMap(params){
    return axios({
        method: 'post',
        url: 'lesson/getLessonMap',
        data: params
    })
}
// 用户已购买课程包获取
export function getLessonPackage(params){
    return axios({
        method: 'get',
        url: 'lesson/getLessonPackage',
        params: params
    })
}
// 保存用户Lesson测验成绩
export function saveUserExerciseResult(params){
    return axios({
        method: 'post',
        url: 'lesson/saveUserExerciseResult',
        data: params
    })
}
// 获取用户.Lesson测验成绩
export function getUserExerciseResult(params){
    return axios({
        method: 'get',
        url: 'lesson/getUserExerciseResult',
        params: params
    })
}
// 获取用户课程章节视频封面信息
export function getLessonPartVideoInfo(params){
    return axios({
        method: 'post',
        url: 'lesson/getLessonPartVideoInfo',
        data: params
    })
}
// 单独解锁练习听
export function setLessonVipActivation(params){
    return axios({
        method: 'post',
        url: 'lesson/setLessonVipActivation',
        data: params
    })
}

/**
 * lessonQuestion
 * **/ 
export function getLevelTest(params){
    return axios({
        method: 'get',
        url: 'lessonQuestion/getLevelTest',
        params: params
    })
}

/**
 * folder
 * **/
// 获取用户文件夹列表
export function getUserFolder(params){
    return axios({
        method: 'get',
        url: 'folder/getUserFolder',
        params: params
    })
}
// 获取文件夹该level下单词总数
export function getHskFolderWord(params){
    return axios({
        method: 'post',
        url: 'folder/getHskFolderWord',
        data: params
    })
}
// 获取文件夹单词列表
export function getFolderWord(params){
    return axios({
        method: 'post',
        url: 'folder/getFolderWord',
        data: params
    })
}
// 添加用户自定义文件夹
export function addUserPrivateFolder(params){
    return axios({
        method: 'post',
        url: 'folder/addUserPrivateFolder',
        data: params
    })
}
// 删除自定义文件夹
export function delUserPrivateFolder(params){
    return axios({
        method: 'post',
        url: 'folder/delUserPrivateFolder',
        data: params
    })
}
// 修改自定义文件夹
export function setUserPrivateFolder(params){
    return axios({
        method: 'post',
        url: 'folder/setUserPrivateFolder',
        data: params
    })
}
// 用户添加单词
export function addWord(params){
    return axios({
        method: 'post',
        url: 'folder/addWord',
        data: params
    })
}
// 删除词语
export function deleteUserCustomWord(params){
    return axios({
        method: 'post',
        url: 'folder/deleteUserCustomWord',
        data: params
    })
}
// 移动词语
export function moveUserCustomWord(params){
    return axios({
        method: 'post',
        url: 'folder/moveUserCustomWord',
        data: params
    })
}

/**
 * history
 * **/ 
// 获取用户课程记录  
export function getUserLessonHistory(params){
    return axios({
        method: 'post',
        url: 'history/getUserLessonHistory',
        data: params
    })
}
// getUserLessonHistoryNew
export function getUserLessonHistoryNew(params){
    return axios({
        method: 'post',
        url: 'history/getUserLessonHistoryNew',
        data: params
    })
}
// unLockUserLessonPart
export function unLockUserLessonPart(params){
    return axios({
        method: 'post',
        url: 'history/unLockUserLessonPart',
        data: params
    })
}
export function getUserLessonTeachers(params){
    return axios({
        method: 'post',
        url: 'history/getUserLessonTeachers',
        data: params
    })
}
// 获取用户课程记录(按照Lesson查询)
export function getUserClassNoteByLesson(params){
    return axios({
        method: 'get',
        url: 'history/getUserClassNoteByLesson',
        params: params
    })
}
// 获取用户课堂笔记
export function getUserClassNote(params){
    return axios({
        method: 'get',
        url: 'history/getUserClassNote',
        params: params
    })
}
// 用户课程评价
export function setTeacherEvaluation(params){
    return axios({
        method: 'post',
        url: 'history/setTeacherEvaluation',
        data: params
    })
}
// 定制课程课件列表(模态框内数据)
export function getUserCustomLessonContent(params){
    return axios({
        method: 'get',
        url: 'history/getUserCustomLessonContent',
        params: params
    })
}
// 定制课程课件内容查询
export function getUserCustomCourssewareContent(params){
    return axios({
        method: 'post',
        url: 'history/getUserCustomCourssewareContent',
        data: params
    })
}

/**
 * calendarEvent
 * **/
// 获取用户未开始课程前5条记录
export function getUserCourseNotStarted(params){
    return axios({
        method: 'get',
        url: 'calendarEvent/getUserCourseNotStarted',
        params: params
    })
}
// 获取用户课程日历（按照时间查询）
export function getUserCourseCalendarByDate(params){
    return axios({
        method: 'post',
        url: 'calendarEvent/getUserCourseCalendarByDate',
        data: params
    })
}
// 获取用户最后一条结束的课与最近一条未开始的课
export function getUserLastEndCourse(params){
    return axios({
        method: 'get',
        url: 'calendarEvent/getUserLastEndCourse',
        params: params
    })
}
// 获取课程内学生列表
export function getCalendarEventUser(params){
    return axios({
        method: 'get',
        url: 'calendarEvent/getCalendarEventUser',
        params: params
    })
}
// 获取用户课程日历
export function getUserCourseCalendarByMonth(params){
    return axios({
        method: 'get',
        url: 'calendarEvent/getUserCourseCalendarByMonth',
        params: params
    })
}
// 获取用户某天排课记录
export function getUserCourseCalendarList(params){
    return axios({
        method: 'get',
        url: 'calendarEvent/getUserCourseCalendarList',
        params: params
    })
}
// 获取用户课程聊天记录
export function getUserChatRecord(params){
    return axios({
        method: 'post',
        url: 'calendarEvent/getUserChatRecord',
        data: params
    })
}
// 保存用户聊天记录
export function saveUserChatRecord(params){
    return axios({
        method: 'post',
        url: 'calendarEvent/saveUserChatRecord',
        data: params
    })
}
// 用户课程签到
export function saveUserCourseSignIn(params){
    return axios({
        method: 'get',
        url: 'calendarEvent/saveUserCourseSignIn',
        params: params
    })
}
// 保存用户课堂日志
export function saveUserCourseLog(params){
    return axios({
        method: 'post',
        url: 'calendarEvent/saveUserCourseLog',
        data: params
    })
}

/**
 * contract
 * **/
// 学生查询自己合同列表
export function getUserContract(params){
    return axios({
        method: 'get',
        url: 'contract/getUserContract',
        params: params
    })
}
// 查询合同详情
export function getUserContractContent(params){
    return axios({
        method: 'get',
        url: 'contract/getUserContractContent',
        params: params
    })
}

/**
 * setting
 * **/
// 用户设置修改
export function updateUserSetting(params){
    return axios({
        method: 'post',
        url: 'setting/updateUserSetting',
        data: params
    })
}
// 用户信息修改
export function updateUserInfo(params){
    return axios({
        method: 'post',
        url: 'setting/updateUserInfo',
        data: params
    })
}

// 根据用户id获取用户信息
export function refreshUserSetting(params){
    return axios({
        method: 'get',
        url: 'setting/refreshUserSetting',
        params: params
    })
}
// 图片上传
export function uploadUserPieture(params){
    return axios({
        method: 'post',
        url: 'setting/uploadUserPieture',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: params
    })
}
// 获取国籍列表
export function getSysCountry(params){
    return axios({
        method: 'get',
        url: 'setting/getSysCountry',
        params: params
    })
}
//获取时区信息列表
export function getUseTimeZone(params){
    return axios({
        method: 'get',
        url: 'setting/getTimeZoneList',
    })
}
// 修改用户level test code
export function setUserTestLevelCode(params){
    return axios({
        method: 'get',
        url: 'setting/setUserTestLevelCode',
        params: params
    })
}
// 获取最大的level
export function getUserActiveMaxLevel(params){
    return axios({
        method: 'get',
        url: 'setting/getUserActiveMaxLevel',
        params: params
    })
}

/**
 * audio
 * **/
// 句子发音识别
export function recognizeAudio(params){
    return axios({
        method: 'post',
        url: 'audio/recognizeAudio',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: params
    })
}
// 语音识别 老系统接口
export function recognize(params){
    return axios({
        method: 'post',
        url: 'https://api.nihaocafe.com/ws/api/speechRecognition/recognize',
        data: params
    })
}

/**
 * lessonContent
 * **/ 
// 获取单词卡片
export function getLessonWordContent(params){
    return axios({
        method: 'get',
        url: 'lessonContent/getLessonWordContent',
        params: params
    })
}

/**
 * ad
 * **/ 
// 广告信息获取
export function getNhcafeLessonAd(params){
    return axios({
        method: 'get',
        url: 'ad/getNhcafeLessonAd',
        params: params
    })
}

/**
 * prepareLessons
 * **/
// 获取已添加课件列表
export function getPrepareLessonContent(params){
    return axios({
        method: 'get',
        url: 'prepareLessons/getPrepareLessonContent',
        params: params
    })
}
// 获取白板某个单词的完整内容
export function getWhiteboardNoteInfo(params){
    return axios({
        method: 'get',
        url: 'prepareLessons/getWhiteboardNoteInfo',
        params: params
    })
}
// 查询白板语法课件详情
export function getWhiteboardGrammerInfo(params){
    return axios({
        method: 'get',
        url: 'prepareLessons/getWhiteboardGrammerInfo',
        params: params
    })
  }

/**
 * courseContent 上课
 * **/
// 获取课件内容
export function getCourseContent(params){
    return axios({
        method: 'get',
        url: 'courseContent/getCourseContent',
        params: params,
    })
}
export function getUserCourseSetting(params){
    return axios({
        method: 'get',
        url: 'courseContent/getUserCourseSetting',
        params: params
    })
}
// 获取课后作业
export function getHomeworkInfo(params){
    return axios({
        method: 'get',
        url: 'courseContent/getHomeworkInfo',
        params: params
    })
}
// 删除
export function deleteHomeWork(params){
    return axios({
        method: 'get',
        url: 'courseContent/deleteHomeWork',
        params: params
    })
}

/**
 * dashboard
 * **/ 
// 获取课程留言板
export function getUserCourseDiscuss(params){
    return axios({
        method: 'get',
        url: 'dashboard/getUserCourseDiscuss',
        params: params
    })
}
// 添加课程留言
export function addUserCourseDiscuss(params){
    return axios({
        method: 'post',
        url: 'dashboard/addUserCourseDiscuss',
        data: params
    })
}
// 课后作业附件上传
export function uploadHomeworkFile(params){
    return axios({
        method: 'post',
        url: 'dashboard/uploadHomeworkFile',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: params
    })
  }
  // 添加课后作业
export function addHomework(params){
return axios({
    method: 'post',
    url: 'dashboard/addHomework',
    data: params
})
}

// 课后作业附件上传
export function uploadFeedback(params){
    return axios({
        method: 'post',
        url: 'upload/uploadNotificationFile',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: params
    })
  }

  export function SendFeedback(params){
    return axios({
        method: 'post',
        url: '/notification/addStudentSystemFeedback',
        data: params
    })
    }
/**
 * upload
 * **/
// 上课聊天文件上传
export function uploadChatRecordFile(params){
    return axios({
        method: 'post',
        url: 'upload/uploadChatRecordFile',
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 1000 * 60 * 2,
        data: params
    })
}