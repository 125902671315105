<template>
  <div class="page-title-container">
    <template v-for="(item, index) in pageTitleList">
      <div
        class="page-title"
        v-if="index + 1 === pageIndex"
        :class="{ active: pageIndex === index + 1 && titleType === 0 }"
        @click="switchPage(0)"
        :key="index + 'page'"
      >
        <span class="font-lesson-title">{{ item.title }}</span>
      </div>
      <div
        class="page-title"
        :key="index"
        v-if="item.target && index + 1 === pageIndex"
        :class="{ active: pageIndex === index + 1 && titleType === 1 }"
        @click="switchPage(1)"
      >
        <span class="font-lesson-title">{{
          item.target ? item.target : ""
        }}</span>
      </div>
    </template>

    <div
      class="page-title"
      @click="switchPage(2)"
      :class="{ active: titleType === 2 }"
    >
      <span class="font-lesson-title">Whiteboard</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageIndex: {
      type: Number,
      require: true,
    },
    pageTitleList: {
      type: Array,
      require: true,
    },
    authority: {
      //权限开关
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      titleType: 0, // 默认课程类型 0 课程  1 目标  2 白板
    };
  },
  mounted() {
    this.$bus.$on("changeTitleActive", (value, isFromSocket) => {
      this.switchPage(value, isFromSocket);
    });
  },
  beforeDestroy() {
    this.$bus.$off("changeTitleActive");
  },
  methods: {
    switchPage(value, isFromSocket) {
      if (!this.authority) {
        return;
      }
      this.titleType = value;
      if (!isFromSocket) {
        this.$emit("displayWhichPage", value);
      }
      console.log(this.titleType);
      if (this.titleType === 2) {
        this.$bus.$emit("changeWhiteBoardPage", 0);
        this.$bus.$emit("OpenGlobalWhiteBoard", false);
      } else {
        this.$bus.$emit("changeWhiteBoardPage", 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title-container {
  width: 100%;
  display: flex;
  align-items: flex-end;

  .page-title {
    max-height: 33.5px;
    background-color: #af4035;
    border-top-right-radius: 17px;
    border-top-left-radius: 17px;
    text-align: center;
    // line-height: 33.5px;
    margin-right: 3%;
    text-align: center;
    padding: 1.5% 5%;

    &:hover {
      cursor: pointer;
    }

    span {
      color: #ffffff;
      font-size: 0.9rem;
      font-family: "GenSenRoundedTW";
      white-space: nowrap;
    }
  }

  .page-title:hover {
    padding: 2.5% 5%;
    background-color: #cd4c3f;
  }

  .active {
    padding: 2.5% 5%;
    background-color: #cd4c3f;
  }
}
</style>
