
<template>
  <!-- layout-container -->
  <div class="layout-container" v-if="lessonInfo">
    <div class="layout-header">
      <CourseHeader
        :lessonNum="lessonInfo.lesson"
        :pageNum="pageNum"
        :pageNumTotal="lessonInfo.totalPage"
        @jumpTo="changePage"
        :authority="AUTH"
      />
    </div>
    <div class="layout-content">
      <div class="layout-content-left">
        <!-- page 标题 -->

        <div class="layout-content-left-header">
          <!-- 课程标题 -->
          <div class="page-title-box">
            <PageTitle
              :pageIndex="pageNum"
              @displayWhichPage="showPage"
              :pageTitleList="lessonInfo.pageTitleArray"
              :authority="AUTH"
            />
          </div>

          <!-- 白板控制器  翻页 -->
          <div class="control-box">
            <div class="white-board-control" v-if="AUTH && !isShowTeacherMouse">
              <WhiteBoardControl :whiteBoradPage="controlWriteboard" />
            </div>

            <div class="page-turning">
              <div
                class="previous"
                @click="prevClick"
                v-show="pageNum > 1"
              ></div>
              <div
                class="nextPrevious"
                @click="nextClick"
                :class="{isInvisible:(pageNum >= lessonInfo.totalPage)}"
              ></div>
            </div>
          </div>
        </div>

        <!-- 课程主体部分 -->

        <div class="layout-content-left-display" ref="interactionCanvasBox">
          <div class="shade-box" v-if="!AUTH"></div>
          <div
            class="teacher-mouse-move-area"
            v-if="isShowTeacherMouse"
            ref="mouseMoveBox"
          >
            <div
              class="mouse-area"
              :style="{ left: mouseX + 'px', top: mouseY + 'px' }"
            ></div>
          </div>
          <RouterView />
        </div>
      </div>

      <!-- 交互区 -->
      <div class="layout-content-right">
        <!-- todolist: 需要做传值修改 -->
        <Interaction :authority="AUTH" />
      </div>
    </div>
    <div class="finshed-mask-area" v-if="isShowFinishedMask">
      <!-- isShowFinishedMask -->
      <div class="mask-content">
        <div class="mask-title font-hanzi-medium">
          <span>Class Finished</span>
        </div>
        <div class="mask-img">
          <img src="@/assets/img/19-Dashboard/Home/class-finished.svg" alt="" />
        </div>
        <div class="mask-tips">
          <p>Your class is now finished!</p>
          <p>This window is about to close.</p>
          <p>
            Don’t worry — you will be able to review this class in the Review
            section.
          </p>
          <!-- <p>
            This window will close in
            <span class="time-number">{{ countDownTime }}</span> seconds
          </p> -->

          <div class="back-home">
            <div class="back-button" @click="handleBackHome">
              Return to Dashboard
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CourseHeader from "@/components/Course/Header";
import PageTitle from "@/components/Course/PageTitle";
import Interaction from "@/components/Course/Interaction";
import WhiteBoardControl from "@/components/Course/WhiteBoard/WhiteBoardControl.vue";

import { lessonDetail } from "@/assets/js/default/lesson.js";
import { webSocketDefault } from "@/mixins/kidsSocketDefault.js";
// webcsocket 事件
import { socketSwitch } from "@/utils/socketSwitch.js";
import { userPunchClock } from "@/assets/js/axios/kidsApi";

export default {
  name: "Course",
  mixins: [webSocketDefault],
  components: {
    CourseHeader,
    PageTitle,
    Interaction,
    WhiteBoardControl,
  },
  data() {
    return {
      changePageBtn: 0,
      changePageNum: 0,
      linkNum: 0,
      pageNum: 1, // 默认第一页
      controlWriteboard: false,
      showWhiteBoard: false,
      lessonInfo: {},
      AUTH: true, // 全局权限,默认打开
      routeIndex: "",
      isShowFinishedMask: false,
      countDownTime: 10,
      punchTimeStamp: null,
      isShowTeacherMouse: false,
      mouseX: null,
      mouseY: null,
    };
  },

  watch: {
    $route(value) {
      // console.log(value, "url变化");
      const index = parseInt(value.name.split("Lesson")[1]);
      // console.log(index);

      this.routeIndex = index;
      if (this.routeIndex === 999) {
        this.lessonInfo = lessonDetail[lessonDetail.length - 1];
      } else {
        this.lessonInfo = lessonDetail[index - 1];
      }
      // console.log(this.lessonInfo);

      this.pageNum = 1;
    },
    linkNum() {
      // 测试socket
      const data = {
        clickType: 10002,
        data: { value: this.routeIndex },
        text: "测试切换课件按钮socket",
      };
      this.sendSocketInfo = { ...data };
      this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
    },
    pageNum(index) {
      // 给页面发送点击  progress  或者  上下一页按钮
      this.$bus.$emit("changeCoursePage", index);
    },
    changePageNum() {
      // 给页面发送点击  progress  或者  上下一页按钮
      // 测试socket
      let pageInfo = { val: this.pageNum, type: 1 };
      const data = {
        clickType: 10001,
        data: pageInfo,
        text: "测试所有翻页按钮socket",
      };
      this.sendSocketInfo = { ...data };
      this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
    },
    changePageBtn() {
      let pageInfo = { val: this.pageNum, type: 1 };
      const data = {
        clickType: 10001,
        data: pageInfo,
        text: "测试所有翻页按钮socket",
      };
      this.sendSocketInfo = { ...data };
      this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
    },
    // isShowFinishedMask(value) {
    //   if (value) {
    //     this.courseCountDown();
    //   }
    // },
    // 监听打卡
    punchTimeStamp(value) {
      // console.log(this.$store.state.dashboard.courseInfo);
      const startTime = new Date(
        this.$store.state.dashboard.courseInfo.classTime
      ).getTime();
      const endTime = new Date(
        this.$store.state.dashboard.courseInfo.endTime
      ).getTime();
      // console.log(value >= startTime - 10 * 60 * 1000 && value <= endTime);
      if (value >= startTime - 10 * 60 * 1000 && value <= endTime) {
        this.punchClock();
      }
    },
  },
  mounted() {
    // 处理是否结课
    // this.handleClassIsFinished();
    // 监听页面内button跳转下一页
    this.$bus.$on("changePageIndex", (index) => {
      this.changePage(index.val, index.type);
    });
    // soket触发切换课件
    this.$bus.$on("kidsSocketSwitchLesson", (data) => {
      this.linkto(data, 1);
    });
    // soket触发切换课件
    this.$bus.$on("kidsAuthority", (value) => {
      // const test = value ? "打开权限" : "关闭权限";
      this.AUTH = value;
    });
    this.$bus.$on("endClassSocket", () => {
      this.isShowFinishedMask = true;
      // this.courseCountDown();
    });
    // 监听触发小手模式
    this.$bus.$on("teacherMouseMove", (value) => {
      // const test = value ? "打开权限" : "关闭权限";
      this.isShowTeacherMouse = value;
      // console.log("teacherMouseMove", value);
    });
    // 鼠标坐标
    this.$bus.$on("mouseOffsetXY", ({ x, y }) => {
      this.$nextTick(() => {
        // console.log(x, y);
        const mouseMoveWidth =
          this.$refs.mouseMoveBox.getBoundingClientRect().width;
        const mouseMoveHeight =
          this.$refs.mouseMoveBox.getBoundingClientRect().height;
        this.mouseX = x * parseInt(mouseMoveWidth) - 20;
        this.mouseY =
          y * parseInt(mouseMoveHeight) - 20 + 40 > mouseMoveHeight - 40
            ? mouseMoveHeight - 40
            : y * parseInt(mouseMoveHeight) - 20;
      });
    });
  },
  created() {
    this.getTimeStamp();
    const index = parseInt(this.$route.name.split("Lesson")[1]);
    console.log(lessonDetail);
    if (index === 999) {
      this.lessonInfo = lessonDetail[lessonDetail.length - 1];
    } else {
      this.lessonInfo = lessonDetail[index - 1];
    }
    this.pageNum = 1;
  },
  beforeDestroy() {
    this.$bus.$off("changePageIndex");
    this.$bus.$off("kidsSocketSwitchLesson");
    this.$bus.$off("kidsAuthority");
    this.$bus.$off("endClassSocket");
    clearInterval(this.pumchTimer);
  },
  methods: {
    async punchClock() {
      const result = await userPunchClock({
        calendarEventId: this.$store.state.dashboard.courseInfo.calendarEventId,
      });
      if (result && result.code === 200) {
        console.log("学生签到");

        clearInterval(this.pumchTimer);
      }
    },
    getTimeStamp() {
      this.pumchTimer = setInterval(() => {
        this.punchTimeStamp = new Date().getTime();
      }, 1000);
    },
    handleClassIsFinished() {
      // console.log(this.$store.state.dashboard.courseInfo);
      const nowTimestamp = new Date().getTime();
      const classEndTime = this.$store.state.dashboard.courseInfo.endTime;
      this.isShowFinishedMask = nowTimestamp - classEndTime >= 0 ? true : false;
    },
    // // 课程倒计时
    // courseCountDown() {
    //   if (this.isShowFinishedMask) {
    //     const timer = setInterval(() => {
    //       this.countDownTime--;
    //       if (this.countDownTime === 0) {
    //         clearInterval(timer);
    //         this.$router.push({ name: "Home" });
    //       }
    //     }, 1000);
    //   }
    // },

    // 回首页
    handleBackHome() {
      this.$router.push({ name: "Home" });
    },
    linkto(data, type) {
      if (type != 1) {
        this.linkNum++;
      }
      this.$router.push({
        name: `Lesson${data.index}`,
      });
    },
    // socket接收信息
    getMessage(msg) {
      let data = JSON.parse(msg.data);
      socketSwitch(data);
    },
    // 跳页面
    changePage(value, type) {
      if (type != 1) {
        this.changePageNum++;
      }
      this.pageNum = value;
    },
    // 切换白板和课程页面
    showPage(value) {
      // 给页面发送切换课程目标页
      this.$bus.$emit("switchPageType", value);

      // 测试socket
      const data = {
        clickType: 10003,
        data: { value },
        text: "测试切换课程目标页面按钮socket",
      };
      this.sendSocketInfo = { ...data };
      this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
    },
    nextClick() {
      // console.log("下一页");
      if (this.AUTH && !this.isShowTeacherMouse) {
        this.pageNum++;
        this.changePageBtn++;
      }
    },
    prevClick() {
      // console.log("上一页");
      if (this.AUTH && !this.isShowTeacherMouse) {
        this.pageNum--;
        this.changePageBtn++;
      }
    },
  },
};
</script>
>

<style lang="scss" scoped>
@import "@/components/Course/Layout/index.scss";
</style>
